.chat-container {
    position: fixed;
    top: 500px;
    right: 0;
    width: 100px;
    height: 70px;
    border-radius: 70px 0 0 70px;
    background: var(--color-green-lighter);
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        .chat-icon {
            transform: translateY(-5px);
        }
    }

    .chat-icon {
        min-width: 40px;
        height: 30px;
        border-radius: 3px;
        position: relative;
        transition: transform var(--transition);

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 6px solid var(--color-white-primary);
            border-radius: 1px;
            transform: rotate(180deg);
            position: absolute;
            bottom: -6px;
            left: 7px;
        }
    }

    .chat-icon-container {
        background: var(--color-white-primary);
        margin-left: 15px;
        z-index: 5;

    }

    .chat-icon-shadow {
        background: var(--color-green-dark);
        margin-top: 6px;
        margin-left: -37px;
        z-index: 4;

        &::after {
            border-bottom: 6px solid var(--color-green-dark);
        }
    }
}