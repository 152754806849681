@import '../../../static/sass/media';

.pauseplay-container {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid var(--color-white-primary);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    @media (min-width: $tablet) {
        width: 70px;
        height: 70px;
    }

    .pauseplay-btn {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 11.5px solid var(--color-white-primary);
        transform: rotate(90deg);
        margin-left: 4px;

        @media (min-width: $tablet) {
            border-left-width: 12.5px;
            border-right-width: 12.5px;
            border-bottom-width: 20px;
        }
    }
}