.container {
  width: 100%;
  min-height: 800px;
  padding-left: 150px;
  padding-right: 150px;

  @media screen and (max-width: 1080px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  padding-top: 50px;
  padding-bottom: 50px;

  .mainSpotContainer {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    margin-bottom: 30px;

    @media screen and (min-width: 1700px) {
      padding-right: 200px;
    }

    @media screen and (max-width: 1080px) {
      flex-direction: column;
      padding-right: 0px;
      gap: 10px;
    }
  
    .textContainer {
      padding-top: 70px;
      //background-color: yellow;
      width: 40%;

      @media screen and (max-width: 1080px) {
        width: 100%;
        padding-top: 10px;

        h1 {
          text-align: center;
        }
      }

      h1 {
        margin-bottom: 40px;
      }
    }
  }

  .otherVideosContainer {
    display: flex;
    gap: 30px;
    margin-bottom: 50px;

    @media screen and (max-width: 1080px) {
      flex-direction: column; 
    }
  }

  .productsContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-bottom: 150px;

    h1 {
      text-align: center;
    }

    @media screen and (max-width: 1080px) {
      flex-wrap: wrap;
      margin-bottom: 80px;
    }
  }

  .cambiarContainer {
    display: flex;
    gap: 50px;
    margin-bottom: 100px;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
    }

    h1 {
      height: fit-content;
      width: fit-content;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 9px;
        background: #59058D;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    .highlight {
      color: #59058D;
      font-weight: bold;
    }

    .cambiarTextContainer {
      p {
        margin-bottom: 10px;
      }

      p:last-of-type {
        margin-bottom: 20px;
      }
      
      .conocerMasContainer {
        position: relative;
        width: fit-content;
        padding: 20px;
        cursor: pointer;

        $animation-speed: 0.3s;

        &:hover .conocerMasBar {
          height: 100%;
        }

        &:hover a {
          color: white;
        }

        a {
          transition: color $animation-speed;
        }

        .conocerMasBar {
          z-index: -1;
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 5px;
          background-color: #59058D;
          transition: height $animation-speed;
        }
      }
    }
  }

  .testeadoContainer {
    display: flex;
    gap: 50px;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
    }

    .testeadoTextContainer {
      h1 {
        width: fit-content;
        height: fit-content;
       
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 9px;
          background: #F4D118;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }

      p {
        margin-bottom: 10px;
      }

      p:last-of-type {
        margin-bottom: 20px;
      }
      
      h2 {
        text-align: center;
        width: 200px;
        height: fit-content;
        cursor: pointer;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 5px;
          background: #59058D;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }

      .hashtag {
        display: inline-block;
        width: fit-content;
        color: #EC7F1C;
        font-weight: bold;

        &:hover::after {
          width: 100%;
        }

        &::after {
          transition: width 0.3s;
          content: '';
          display: block;
          width: 0;
          height: 3px;
          background: #EC7F1C;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
