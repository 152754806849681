@import '../../../static/sass/media';

.product-list-container {
    margin-bottom: 100px;

    @media (max-width: $tablet) {
        max-width: 500px;
        margin: 15px auto 100px;
    }




    .product-list-filter-container {
        width: fit-content;
        margin-left: auto;
        margin-bottom: 40px;
        position: relative;

        @media (max-width: $tablet) {
            display: none;
        }

        .arrow {
            position: absolute;
            border-color: var(--color-grey-primary);
            right: 15px;
            top: 15px;
            border-width: 0 3px 3px 0;
            padding: 4px;
        }

        select {
            padding: 14px 14px;
            padding-right: 70px;
            border-radius: 0px;
            border: 1px solid var(--color-grey-light);
            color: var(--color-grey-primary);
            font-size: 13px;
            font-weight: var(--font-bold);
            font-family: var(--font-family-primary);
            appearance: none;

            &:focus {
                outline: none;
            }
        }
    }

    .product-list-list-container {
        display: flex;
        flex-wrap: wrap;

        &>div {
            margin-bottom: 50px;
            flex-basis: 32%;
            margin-right: 10px;

        }

        @media (min-width: 555px) {
            &>div {

                &:nth-last-child(-n+3) {
                    margin-bottom: 0px;
                }

                &:nth-child(3n) {
                    margin-right: 0px;
                }

            }
        }

        @media (max-width: 555px) {
            justify-content: center;

            &>div {
                min-width: 43vw;
                // width: 139px;
                height: 55vw;
                max-width: 100% !important;

                &:nth-child(2n) {
                    margin-right: 0px;
                }

                &:nth-last-child(-n+2) {
                    margin-bottom: 0px;
                }
            }
        }

        @media (max-width: $tablet) {

            p {
                margin-top: 16px;
            }

        }

        @media (min-width: $tablet) {
            min-width: 67vw;

            @media (min-width: $desktop_md) {
                min-width: 960px;
            }

            &>div {
                // display: inline-block;
                // margin-left: auto;
                height: 290px;
                margin-right: 14px;
                margin-bottom: 80px;

                @media (max-width: $desktop_md) {
                    height: 24vw;
                }

                @media (min-width: $desktop_sm) {
                    p {
                        margin-top: 12px;
                        font-size: 16px;
                    }
                }

                @media (min-width: $desktop_md) {
                    // height: 380px;
                    height: 350px;
                    width: 300px;
                    margin-right: 19px;
                    margin-bottom: 90px;

                    p {
                        margin-top: 10px;
                        font-size: 18px;
                    }
                }

            }
        }
    }

}