@import "../../../static/sass/media";

.prodindv-imgs-container {
  flex: 1;
  display: flex;
  justify-content: space-between;

  @media (max-width: $tablet) {
    display: none;
  }

  .prodindv-moreimgs-container {
    flex: 1;

    @media (min-width: $tablet) {
      flex-basis: 16%;
    }

    .moreimgs-item-container {
      background: var(--color-white-f9);
      width: 90px;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      cursor: pointer;

      @media (min-width: $desktop_md) {
        width: 110px;
        height: 110px;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }

  .prodindv-mainimg-container {
    flex: 5;

    @media (min-width: $tablet) {
      flex-basis: 80%;
      margin-right: 30px;
    }

    .mainimg-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      max-width: 100%;
      // max-width: 20px;

      @media (min-width: $desktop_md) {
        height: 450px;
      }

      .acondicionador-hydrax {
        width: 140px;
        height: 450px;
        object-fit: fill;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;

        @media (min-width: $desktop_md) {
          // height: 520px;
          // width: 520px;
        }
      }
    }
  }
}
