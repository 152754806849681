@import '../../../static/sass/media';


.banner-category-container {
    overflow: hidden;
    // height: 320px;
    height: 320px;
    position: relative;
    z-index: 2;

    @media (min-width: $tablet) {
        height: 270px;
    }

    .banner-category-images-container {
        position: absolute;
        right: 0px;
        width: 100%;
        height: 100%;

        @media (min-width: $mobile_lg) and (max-width: $tablet) {
            right: 40%;
        }

        img {
            object-fit: cover;

            @media (max-width: $mobile_lg) {
                min-width: 100%;
                min-height: 100%;
                width: 100%;
                height: 100%;
            }

            @media (min-width: $mobile_lg) {
                min-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
            }
        }
    }

    .banner-category-content {
        padding-top: 15px;
        padding-left: calc(var(--container-padding) / 2);
        color: var(--color-white-primary);
        position: relative;

        @media (min-width: $tablet) {
            padding-top: 2%;
        }

        a {
            color: currentColor;
        }

        span {
            display: inline-block;
            position: relative;
            cursor: pointer;
            font-size: 11.5px;
            margin-bottom: 5px;
            margin-top: 10px;
            font-weight: var(--font-medium);
            transform: translateX(16px);

            @media (min-width: $tablet) {
                font-size: 13.2px;
                margin-bottom: 10px;
                margin-top: 0px;
            }

            // @media (min-width: $desktop_md) {
            //     font-size: 16.2px;
            // }


        }

        h4 {
            z-index: 10;
            position: relative;
            margin-top: 15px;
            font-size: 20px;
            max-width: 250px;
            // letter-spacing: 1px;

            @media (max-width: $tablet) {
                font-weight: var(--font-medium) !important;
            }

            @media (min-width: $tablet) {
                margin-top: 20px;
                font-size: 28px;
                max-width: 800px;

                &::after {
                    display: none;
                }
            }

            &.line {
                margin-bottom: 45px;

                @media (min-width: $tablet) {
                    margin-bottom: 0px;

                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -25px;
                    left: 0;
                    width: 40px;
                    height: 4px;
                    background: white;
                }
            }



            // @media (min-width: $desktop_md) {
            //     font-size: 30px;
            // }
        }

        .banner-category-line {
            background: var(--color-grey-light);
            height: 4px;
            width: 35px;
            margin-top: 20px;

            @media (min-width: $tablet) {
                width: 50px;
                margin-top: 25px;
            }

        }

        p {
            z-index: 10;
            position: relative;
            font-weight: var(--font-regular);
            max-width: 200px;
            font-size: 13px;
            margin-top: 20px;

            @media (max-width: $tablet) {
                line-height: 18px;
            }

            @media (min-width: $tablet) {
                max-width: 480px;
                font-size: 16.7px;
                margin-top: 25px;
                line-height: 25px;
            }


        }

        img {
            position: absolute;
            z-index: 1;

            &.top-center-big {
                top: 97%;
                left: 19%;
                height: 72%;

                @media (min-width: $tablet) {
                    top: -170px;
                    left: 530px;
                    height: 500px;
                }
            }

            &.center-small {
                top: 29%;
                left: 47%;
                height: 80%;
                transform: rotate(-25deg);
                z-index: 0;

                @media (min-width: $tablet) {
                    transform: rotate(0deg);
                    top: 50px;
                    left: 915px;
                    height: 250px;
                }
            }

            &.center-small-medium {
                top: 40%;
                left: 60%;
                height: 80%;

                @media (min-width: $tablet) {
                    top: 0px;
                    left: 815px;
                    height: 350px;
                }
            }

            &.center-medium {
                top: 90%;
                left: 60%;
                height: 50%;

                @media (min-width: $tablet) {
                    top: -50px;
                    left: 815px;
                    height: 470px;
                }
            }

            &.center-big {
                top: 95%;
                left: 30%;
                height: 50%;

                @media (min-width: $tablet) {
                    top: -220px;
                    left: 555px;
                    height: 600px;
                }

            }

            &.left-small {
                top: 102%;
                left: -13%;
                height: 60%;

                @media (min-width: $tablet) {
                    top: -80px;
                    left: 500px;
                    height: 250px;
                }
            }

            &.right-big {
                top: 40%;
                left: 40%;
                height: 170%;


                @media (min-width: $tablet) {
                    top: -420px;
                    left: 890px;
                    height: 900px;
                }
            }

            &.right-small {
                top: 40%;
                left: 50%;
                height: 100%;

                @media (min-width: $tablet) {
                    top: 0px;
                    left: 1100px;
                    height: 350px;
                }
            }

            &.right-smaller {
                top: 80%;
                left: 45%;
                height: 70%;
                transform: rotate(-15deg);

                @media (min-width: $tablet) {
                    transform: rotate(0deg);
                    top: 20px;
                    left: 1150px;
                    height: 280px;
                }

            }
        }

    }
}
