.lmmobile-container {
    width: 253px;
    color: var(--color-grey-C8);
    background: white;
    height: 100vh;

    .lmmobile-content {
        padding: 50px 20px 0px;

        .llmobile-li {
            margin-bottom: 20px;
            width: 100%;
            background: currentColor;
            height: 20px;
        }

    }
}