@import '../../../static/sass/media';

.buttonicon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    transition: opacity var(--transition);
    background: var(--color-white-fa);
    z-index: 2;


    @media (min-width: $tablet) {
        padding: 10px;
        border-radius: 10px;
    }

    svg {
        width: 20px;
        height: 20px;
        fill: var(--color-green-dark);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: .8;
    }
}