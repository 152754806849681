.newsletter-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .popup-content {
      background: white;
      padding: 40px;
      text-align: center;
      border-radius: 0px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      min-width: 490px;
  
      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }

      h2{
        color: black;
        padding-bottom: 5px;
      }

      form {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        
        input[type="text"], 
        input[type="email"] {
          width: 100%;
          padding: 12px 10px; 
          font-size: 14px;
          border: 1px solid #858181d9;
          border-radius: 0px;
        }

        div {
          margin-bottom: 10px;
        }
  
        button {
          padding: 15px 30px;
          background: #222222;
          color: white;
          border: none;
          border-radius: 0px;
          cursor: pointer;
          font-weight: 600;
  
          &:hover {
            background: #333;
          }
        }
      }

      p.error-message {
        color: red;
        background: white;
        font-weight: bold;
        margin-top: 10px;
      }
      p.success-message {
        margin-top: 10px;
        color: green;
        font-weight: bold;
      }
    }

    @media screen and (max-width: 600px) {
      .popup-content {
        min-width: 300px;
        padding: 30px 20px;
      }      
    }
  }
  