.veil {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;

  // Este z-index tapa todo menos el menú principal
  z-index: 11;
}
