@import '../../../static/sass/media';

.uso-container {

    h5 {
        color: var(--color-dark-primary);
        font-weight: var(--font-bold);
        font-size: 20px;
        margin-bottom: 16.5px;

        @media (min-width: $tablet) {
            font-size: 25px;
            margin-bottom: 40px;
        }
    }

    .uso-carrouselheader-container {
        margin-bottom: 30px;

        .carrouselheader-line {
            width: 100%;
            height: 2px;
            background: var(--color-grey-mediumdark);
            margin-bottom: 15px;

            .carrouselheader-progress-line {
                // background: var(--color-green-light);
                height: 4px;
                width: 100px;
                transition: width var(--transition);
                transform: translateY(-1px);
            }
        }

        .carrouselheader-items-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .carrouselheader-number-container {
                display: flex;
                align-items: center;

                span {
                    font-weight: var(--font-bold);
                    font-size: 12px;

                    @media (min-width: $tablet) {
                        font-size: 15px;
                    }

                    &:nth-of-type(1) {
                        font-size: 22px;
                        // color: var(--color-green-light);

                        @media (min-width: $tablet) {
                            font-size: 30px;
                        }
                    }

                    &:nth-of-type(2) {
                        margin: 0px 10px;
                        color: var(--color-grey-mediumdark);
                    }
                }
            }

            .carrouselheader-buttons-container {
                display: flex;

                @media (max-width: $tablet) {
                    display: none;
                }

                .carrouselheader-button-item {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background var(--transition);
                    background: var(--color-grey-thiner);

                    &+div:nth-child(1) {
                        padding-left: 2px;
                    }

                    &+div:nth-child(2) {
                        padding-right: 2px;
                    }

                    &.active {
                        // background: var(--color-green-light);
                    }

                    .left {
                        margin-left: 3px;
                    }
                }
            }
        }
    }

    .uso-carrousel-container {
        overflow: hidden;
        display: flex;

        &>div {
            min-width: 100%;
            transition: transform var(--transition);
            display: flex;
            flex-direction: column;

            @media (min-width: $tablet) {
                align-items: flex-start;
                flex-direction: row;
                justify-content: space-between;
            }

            .car-item-container1 {
                flex-basis: 10%;
                // height: 100%;
                min-width: 10%;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: $tablet) {
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 30px;
                }

                @media (min-width: $tablet) {
                    flex-basis: 20%;
                    margin-top: 20px;
                    min-width: 20%;
                }

                img {
                    width: 50%;
                    height: auto;
                    object-fit: cover;

                    @media (max-width: $tablet) {
                        max-width: 150px;
                    }
                }

                .ill-item {
                    width: 116px;
                    height: 116px;
                    border-radius: 50%;
                    // background: var(--color-green-light);

                    @media (max-width: $tablet) {
                        margin-bottom: 42px;
                    }

                    @media (min-width: $tablet) {
                        width: 160px;
                        height: 160px;
                    }

                    @media (min-width: $desktop_md) {
                        width: 180px;
                        height: 180px;
                    }
                }
            }

            .caritem1-info-container {
                margin-left: auto;
                margin-right: auto;
            }

            .car-item-container2 {
                // flex-basis: 75%;
                width: 75%;

                margin-left: auto;
                margin-right: auto;

                h4 {
                    font-size: 20px;
                    color: var(--color-dark-primary);
                    margin-bottom: 22px;

                    @media (min-width: $tablet) {
                        font-size: 26px;
                        margin-bottom: 24px;
                    }

                    @media (min-width: $desktop_md) {
                        font-size: 30px;
                        margin-bottom: 28px;
                    }
                }

                .opt-descr {
                    margin-bottom: 20px;
                }

                .caritem-p-content {
                    .caritem-maincontent-container {
                        color: var(--color-grey-primary);
                        max-width: 100%;
                        font-size: 12px;
                        margin-bottom: 20px;

                        ul {
                            margin-left: 30px;
                        }

                        @media (min-width: $tablet) {
                            margin-bottom: 30px;
                            font-size: 14px;
                            line-height: 1.7;
                            max-width: 88%;
                        }


                        @media (min-width: $desktop_md) {
                            max-width: 90%;
                            font-size: 17px;
                        }
                    }

                }
            }
        }
    }

    .uso-mobilecar-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        @media (min-width: $tablet) {
            display: none;
        }
    }
}
