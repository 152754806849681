@import '../../../static/sass/media';

.filtermenu-container {
    position: fixed;
    z-index: 12;
    transition: transform 300ms ease;
    transform: translateX(-100vw);
    width: 100vw;
    background: white;
    left: 0;
    top: 55px;
    height: calc(100vh - 55px);
    overflow-y: scroll;

    @media (min-width: $tablet) {
        display: none;
    }

    &.active {
        transform: translateX(0vw);
    }

    .filtermenu-content {


        .header-container {
            height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 22px 0px 27px var(--container-padding);
            border-top: 1px solid var(--color-grey-E4);
            border-bottom: 1px solid var(--color-grey-E4);

            .header-svg-container {

                svg {
                    height: 25px;
                    transform: rotate(180deg);
                    fill: var(--color-grey-primary);
                }
            }

            h4 {
                font-weight: var(--font-semibold);
                color: var(--color-grey-primary);
                font-size: 18px;
            }
        }

        .menu-item-container {

            &.active {
                background: var(--color-grey-mediumlight);
            }

            .menu-item-content {
                border-bottom: 1px solid var(--color-grey-E4);
                background: white !important;

                &::after {
                    display: none;
                }

                span,
                a {
                    font-weight: var(--font-regular) !important;
                    color: var(--color-dark-primary);
                    text-transform: none;
                }
            }

            .item-dropdown-container {

                .item-dropdown-item {
                    // padding-left: var(--container-padding);
                    background: var(--color-grey-F5);
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }

        }


    }
}