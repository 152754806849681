@import '../../../../static/sass/media';

.cs-container {
    margin-bottom: 35px;

    .cs-top {
        cursor: pointer;

        .cs-top-content {
            display: flex;
            justify-content: space-between;
            color: var(--color-dark-primary);
            border-bottom: 1px solid var(--color-grey-mediumdark);
            padding-bottom: 6px;
            position: relative;

            &.active {
                color: var(--color-green-dark);
                border-color: var(--color-green-dark);
            }

            span {
                font-size: 12px;

                @media (min-width: $tablet) {
                    font-size: 17px;
                }
            }

            .cs-icon-container {
                position: absolute;
                top: 5px;
                right: 0px;

                .cs-icon {
                    width: 6px;
                    height: 6px;
                    border-color: currentColor;
                    border-bottom: 2.5px solid;
                    border-right: 2.5px solid;
                    transform: rotate(45deg);
                }
            }

        }
    }

    .cs-bottom {
        // max-height: 500px;
        padding-top: 20px;


        @media (min-width: $tablet) {
            padding-top: 30px;
        }

        .cs-bottom-item {

            ul {

                li:not(:last-of-type) {
                    margin-bottom: 19px;
                }


                li {
                    color: var(--color-grey-mediumdark);
                    font-size: 12px;
                    cursor: pointer;

                    @media (min-width: $tablet) {
                        font-size: 17px;
                    }

                    &:focus {
                        color: var(--color-dark-primary);
                        outline: none;
                    }

                    span {}
                }
            }
        }

        .cs-bottom-chkboxes {

            ul {

                li {
                    display: flex;
                    align-items: center;
                    position: relative;
                    transition: color 300ms ease;

                    &.active {
                        color: var(--color-dark-primary);

                        input {
                            border: 1px solid var(--color-green-dark);
                        }

                        &::after {
                            transform: scale(1);
                        }

                    }

                    input {
                        border: 1px solid var(--color-grey-mediumdark);
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        margin-right: 8.25px;
                        transition: border 300ms ease;

                        @media (min-width: $tablet) {
                            width: 17px;
                            height: 17px;
                            margin-right: 14px;
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        background: var(--color-green-dark);
                        top: 2px;
                        left: 2px;
                        border-radius: 50%;
                        transform: scale(0);
                        transform-origin: center;
                        transition: transform 300ms ease;

                        @media (min-width: $tablet) {
                            top: 5px;
                            left: 3px;
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
            }
        }
    }

}