@import '../../../static/sass/media';

.categorias-banner-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;

    // @media (max-width: $tablet) {
    //     display: none;
    // }

    @media (min-width: $tablet) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .categoria-item-container {
        flex-basis: 50%;
        max-height: 80px;
        height: 80px;

        @media (min-width: $tablet) {
            flex: 1;
            max-height: 60px;
            height: 60px;
        }

        @media (min-width: $tablet) and (max-width: $desktop_sm) {
            &:nth-child(4) {
                .categoria-item-content {
                    padding-left: 10% !important;
                }
            }
        }


        &.categoriaproducto {
            max-height: 50px;

            @media (max-width: $tablet) {
                display: none;
            }

            .categoria-item-content {
                height: 50px;
                justify-content: center !important;
                padding-left: unset !important;
            }
        }

        &.active {
            height: 73px;
            max-height: 73px;

            &:nth-child(4) {
                .categoria-item-content {
                    padding-left: 70px;
                }
            }

            .categoria-item-content {
                height: 100%;
                justify-content: flex-start;
                padding-left: var(--container-padding);

            }

        }

        &.inactive {
            background: var(--color-grey-mediumlight);

            &:nth-child(4) {
                .categoria-item-content {
                    padding-left: 70px;
                }
            }

            .categoria-item-content {
                justify-content: flex-start;
                padding-left: var(--container-padding);

                img {
                    fill: var(--color-dark-primary);
                }

                h5 {
                    color: var(--color-dark-primary);
                }
            }


        }


        &.products-page {
            max-height: 50px;
            height: 50px;

            .categoria-item-content {
                padding-left: 0px !important;
                justify-content: center;
            }
        }

        .categoria-item-content {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: var(--container-padding);
            height: 100%;
            cursor: pointer;
            box-shadow: 0px 3px 6px #00000029;

            @media (max-width: $mobile_xs) {
                padding-left: 10px;
            }

            @media (min-width: $tablet) {
                justify-content: center;
                padding-left: unset;
            }

            // @media (min-width: $tablet) and (max-width: $desktop_sm) {
            //     padding-left: 20% !important;
            // }


            h5 {
                font-weight: var(--font-semibold);
                font-size: 16.5px;
                max-width: 80%;

                @media (max-width: $mobile_xs) {
                    font-size: 15.5px;
                }


                @media (min-width: $tablet) {
                    // font-size: 18px;
                    font-size: 18px;
                    font-weight: var(--font-bold);
                }

                // @media (min-width: $desktop_md) {
                //     font-size: 20px;
                // }
            }

            img {
                height: 14.4px;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                right: 10px;

                @media (min-width: $tablet) {
                    height: 25px;
                    right: 25px;
                }
            }
        }
    }
}