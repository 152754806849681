@import "../../../static/sass/media";

.producto-container {
  height: 211px;
  min-width: 160px;
  width: 160px;
  // margin-right: 10px;
  transition: transform 300ms ease;

  @media (min-width: $tablet) {
    // margin-right: 0px;
    height: 300px;
    width: 250px;
  }

  @media (min-width: $desktop_md) {
    height: 350px;
    width: 300px;
  }

  .producto-content {
    height: 100%;

    .producto-img-container {
      position: relative;
      height: 100%;
      background: black;

      &:hover {
        .producto-add-btn-container {
          opacity: 1;
        }

        .producto-img {
          opacity: 0.8;
        }
      }

      .producto-add-btn-container {
        position: absolute;
        width: 50px;
        height: 50px;
        background: var(--color-white-primary);
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;
        transition: opacity 200ms ease;

        .producto-add-btn {
          position: relative;
          transform: rotate(45deg) translate(42px, -42px);
          background: grey;

          &::after {
            content: "";
            width: 2px;
            height: 20px;
            background: inherit;
            transform: rotate(45deg);
            position: absolute;
            top: 50px;
          }

          &::before {
            content: "  ";
            width: 2px;
            height: 20px;
            background: inherit;
            transform: rotate(-45deg);
            position: absolute;
            top: 50px;
          }
        }
      }

      .producto-img {
        height: 100%;
        cursor: pointer;
        background: var(--color-grey-thin);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity var(--transition);

        img {
          height: auto;
          max-width: 100%;
          transition: opacity 200ms ease;
        }
      }

      .producto-line {
        width: 100%;
        height: 8px;
        margin-top: auto;

        @media (min-width: $desktop_md) {
          height: 8px;
        }
      }
    }

    p {
      margin-top: 25px;
      text-align: center;
      color: var(--color-dark-secondary);
      font-size: 10px;
      line-height: 1.2;

      @media (min-width: $tablet) {
        font-size: 15px;
      }

      @media (min-width: $desktop_md) {
        margin-top: 20px;
        font-size: 20px;
      }
    }
  }
}
