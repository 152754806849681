.menu-item-container {
    width: 100%;

    &.active {
        .menu-item-content {
            background: var(--color-white-f9);

            &.dropdown {
                &::before {
                    transform: rotate(135deg);
                }

                span {
                    color: currentColor;
                    font-weight: var(--font-semibold);
                }
            }
        }

        .item-dropdown-container {
            max-height: 1000px
        }
    }

    .menu-item-content {
        padding: 20px 0px 20px var(--mobile-padding);
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--color-grey-C8);
        font-size: 14px;

        a {
            font-weight: var(--font-bold);
        }

        &.dropdown {
            position: relative;

            span {
                color: initial;
                text-transform: capitalize;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 6px;
                background: currentColor;
            }

            &::before {
                content: '';
                position: absolute;
                right: calc(var(--mobile-padding) + 2px);
                top: 50%;
                transform: translateY(-70%) rotate(-45deg);
                border: 2px solid currentColor;
                border-top: none;
                border-right: none;
                height: 10px;
                width: 10px;
                transition: transform 0s ease 50ms;
            }

        }

    }


    .item-dropdown-container {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 500ms ease-in-out;
        transform-origin: top;

        .item-dropdown-item {
            width: 100%;
            padding: 15px 0px 15px calc(var(--mobile-padding) + 15px);
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--color-grey-C8);
            background: var(--color-white-f9);

            &.title {

                a,
                span {
                    color: var(--color-dark-primary);
                    font-weight: var(--font-semibold);
                }
            }

            a,
            span {
                padding-right: 20px;
                font-family: var(--font-family-secondary);
                font-size: 13px;
                color: var(--color-grey-78);
            }
        }
    }


}