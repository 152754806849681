@import '../../../static/sass/media';

.button-item {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid currentColor;
    background: none;
    margin-right: 15px;
    cursor: pointer;
    transition: background var(--transition);

    @media (min-width: $desktop_md) {
        width: 15px;
        height: 15px;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background: currentColor;
    }

    &.active {
        background: currentColor;
    }

    &.inactive {
        background: none;
    }

    &:last-of-type {
        margin-right: 0px;
    }
}