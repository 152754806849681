@import '../../../static/sass/media';

.filtros-activos-container {
    min-height: 40px;
    overflow-x: hidden;

    @media (max-width: $tablet) {
        padding-top: 60px;
        max-width: 500px;
        margin: 0 auto;
        position: relative;
        // overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            left: calc((500px - 100vw) / 2);
            top: 0;
            width: 100vw;
            height: 100%;
            background: white;
            z-index: -1;
        }

    }

    &>button {
        @media (max-width: $tablet) {
            display: none;
        }
    }

    .filtros-activos-content {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;


        @media (min-width: $tablet) {
            margin-top: 30px;
            max-width: 239px;
        }

        @media (min-width: $desktop_sm) {
            max-width: 280px;
        }

        .filtro-item-container {
            min-width: 20px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}