@import '../../../static/sass/media';

.tut-banner-container {
    height: 359px;
    // height: 30vh;
    background: url('../../../static/img/bannertut/bannertuthero.png') no-repeat center/cover;
    // background-size: auto 100%;
    display: flex;
    justify-content: center;


    @media (min-width: $tablet) {
        height: 350px;
        align-items: center;
    }

    @media (min-width: $desktop_md) {
        height: 400px;
    }

    .tut-banner-content {
        color: var(--color-white-primary);
        width: fit-content;
        text-align: center;

        @media (max-width: $tablet) {
            padding-top: 137px;
            width: 300px;
        }


        h1,
        p {
            margin-bottom: 24px;

            @media (min-width: $desktop_md) {
                margin-bottom: 28px;
            }
        }

        h1 {
            font-size: 22px;

            @media (min-width: $tablet) {
                font-size: 38px;
            }

            @media (min-width: $desktop_md) {
                font-size: 40px;
            }
        }

        p {
            font-size: 14px;

            @media (min-width: $tablet) {
                font-size: 20px;
            }

            @media (min-width: $desktop_md) {
                font-size: 22px;
            }
        }

        .banner-input-container {
            width: 100%;
            position: relative;
            cursor: pointer;

            form {
                button {
                    background: none;
                    border: none;
                    cursor: pointer;

                    &:focus {
                        outline: none;
                    }
                }
            }

            input {
                width: 100%;
                background: none;
                padding: 18px 22px;
                border: 1px solid rgba($color: white, $alpha: .4);
                color: var(--color-white-primary);
                font-family: var(--font-family-primary);
                font-size: 12px;

                @media (min-width: $tablet) {
                    font-size: 15px;
                    padding: 14px 22px;
                }

                @media (min-width: $desktop_md) {
                    font-size: 17px;
                }

                &::placeholder {
                    color: var(--color-white-primary);
                    opacity: .8;
                }

            }

            .input-svg-container {
                fill: var(--color-white-primary) !important;
                position: absolute;
                right: 20px;
                top: 16px;

                @media (min-width: $tablet) {
                    top: 13px;
                }

                svg {
                    min-height: 19px;
                    min-width: 19px;
                    fill: var(--color-white-primary) !important;

                    @media (min-width: $tablet) {
                        min-height: 22px;
                        min-width: 22px;
                    }

                    // stroke: var(--color-white-primary);
                }
            }
        }
    }
}