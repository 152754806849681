@import '../../../static/sass/media';

.categorias-mobile {
    display: flex;
    flex-wrap: wrap;
    // padding: 15px 0px 15px;
    color: var(--color-dark-primary);

    &.productos {

        @media (max-width: $mobile_xs) {
            top: 3px;
        }

        @media (min-width: $mobile_xs) and (max-width: $tablet) {
            margin-bottom: 20px;
            position: relative;
            top: 1px;
            transform: translateX(0vw);
        }

        @media (min-width: 402px) {
            top: 11px;
        }

    }

    @media (max-width: 550px) {
        transform: translateX(-5vw);
    }

    @media (max-width: $tablet) {
        padding: 0px;
        z-index: 2;
        width: 100vw;
    }

    @media (max-width: $tablet) {
        margin-bottom: 0px;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
        flex-wrap: nowrap;
        max-width: 500px;
        margin: 0 auto;

        .categoria-item-container {
            flex-basis: unset;
            max-height: unset !important;
            height: fit-content !important;
            margin: 0px 4px;


            @media (max-width: $mobile_xs) {
                margin: 0px 2px;
            }

            &.inactive {
                background: none !important;
            }

            &:not(.inactive) {
                color: var(--color-white-primary);
            }

            &.active {

                .categoria-item-content {
                    height: fit-content !important;


                    h5 {
                        color: white;
                        font-weight: var(--font-bold);
                    }
                }
            }

            .categoria-item-content {
                padding-left: unset;
                height: 100%;
                box-shadow: none;
                // max-width: 110px;
                padding: 5px 7px;
                justify-content: center !important;
                padding-left: 7px !important;
                cursor: pointer;

                @media (max-width: $mobile_xs) {
                    padding: 2px 5px;
                    padding-left: 5px !important;
                }

                h5 {
                    font-size: 13px;
                    margin: 0 auto;
                    // padding: 30px 0px;
                    max-width: unset;
                    text-align: center;

                    @media (max-width: $mobile_xs) {
                        font-size: 11px;
                    }

                }

                img {
                    display: none;
                }
            }
        }


    }

}