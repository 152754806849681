@import '../../../static/sass/media';

.thanks-container {
    height: 193px;

    @media (min-width: $tablet) {
        height: 350px;
    }

    .thanks-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .thanks-text-container {
            flex-basis: calc(100% - 5px);
            color: var(--color-dark-primary);
            font-size: 14px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (min-width: $tablet) {
                font-size: 18px;
            }

            &>* {
                margin-bottom: 8px;
            }

            h4 {}

            p {}
        }

        .line-container {

            .actual-line {
                width: 100%;
                height: 5px;
                background: var(--color-green-dark);
            }
        }
    }
}