@import "../../../static/sass/media";

#footer {
  background-color: var(--color-grey-primary);
  color: var(--color-white-primary);
  // padding: 40px 80px;
  padding-left: var(--container-padding);

  @media (min-width: $mobile_lg) {
    display: flex;
    padding: 30px 40px 30px var(--container-padding);
  }

  @media (min-width: $tablet) {
    padding: 40px 40px 55px var(--container-padding);
  }

  li {
    list-style: none;
  }

  .brand {
    @media (min-width: $mobile_lg) {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
    }

    @media (min-width: $tablet) {
      // justify-content: space-between;
      flex-basis: 50%;
    }

    .logo {
      display: flex;

      @media (min-width: $mobile_lg) {
        margin-bottom: 42px;
      }

      .logo-svg-container {
        margin-bottom: 5px;

        svg {
          // height: 25px;
          width: 85px;

          @media (min-width: $mobile_lg) {
            width: 100px;
          }
        }
      }
    }

    .copyright {
      p {
        font-size: 12px;
        margin: 0;

        @media (min-width: $mobile_lg) {
          font-size: 13px;
        }
      }
    }
  }

  .mobile-line-separator {
    width: calc(100% - var(--container-padding));
    height: 2px;
    background: #4d443d;

    @media (min-width: $mobile_lg) {
      display: none;
    }
  }

  .footer-padding {
    padding: 16px 0px;
    width: calc(100% - var(--container-padding));

    &:last-of-type {
      padding-bottom: 40px;

      @media (min-width: $mobile_xs) {
        padding-bottom: 61px;
      }
    }

    @media (min-width: $mobile_lg) {
      padding: 0px 0px !important;
      width: unset;
    }
  }

  .footer-section {
    display: flex;

    // justify-content: space-between;

    @media (min-width: $mobile_lg) {
      // flex-basis: 17%;
      min-width: 119px;

      &:not(:last-of-type) {
        margin-right: 7%;
      }
    }

    &.footer-contact-redes {
      @media (min-width: $mobile_lg) {
        flex-direction: column;
        // flex-basis: 100%;
        margin-right: -58px;
        margin-left: 10px;
      }
    }

    .footer-section-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      color: var(--color-white-f7);
      font-size: 11px;
      margin-top: 1.5em;

      &:nth-child(1) {
        min-width: 110px;
        margin-right: 15px;

        @media (min-width: $mobile_xs) {
          min-width: 120px;
          margin-right: calc(100% - 285px);
        }

        @media (min-width: $mobile_lg) {
          min-width: unset;
          margin-right: 0px;
        }
      }

      @media (min-width: $mobile_xs) {
        font-size: 16px;
      }

      @media (min-width: $mobile_lg) {
        font-size: 16px;
      }

      a {
        color: currentColor;
      }

      &.contact-content {
        height: 38px;

        @media (min-width: $mobile_xs) {
          height: 44px;
        }

        @media (min-width: $mobile_lg) {
          height: 40px;
          margin-bottom: 22px;
        }
      }

      &.footer-redes {
        h4 {
          @media (min-width: $mobile_lg) {
            margin-bottom: 13px;
          }
        }
      }

      .footer-issuecolorecuador {
        padding-top: 10px;
      }

      h4 {
        font-size: inherit;
        font-weight: var(--font-regular);
      }

      span {
        font-size: 13px;
        font-weight: var(--font-bold);

        @media (min-width: $mobile_xs) {
          font-size: 16px;
        }


      }

      .footer-section-logos {
        display: flex;

        .footer-logo-line {
          height: 14px;
          width: 1px;
          background: var(--color-white-f9);
          margin: 0px 14px;
        }

        svg {
          height: 14px;
          cursor: pointer;

          @media (min-width: $mobile_lg) {
            height: 16px;
          }
        }
      }

      /*cambios 25-8*/
      /*
            .footer-country-dropdownlist {
                content: " ";
                display: inline-block;
                vertical-align: middle;
                width: 16px;
                height: 16px;
                position: relative;
                left: -520px;.comprar-container
                bottom: -40px;


            }
            */
      .dropbtn {
        cursor: pointer;
        margin-bottom: 11px;
        background-color: #6c625a;
      }

      .dropdown {
        position: relative;
        display: inline-block;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        img {
          vertical-align: middle;
        }
      }

      .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-size: 13px;
      }

      .dropdown-content a:hover {
        background-color: #f1f1f1;
      }

      .dropdown:hover .dropdown-content {
        display: block;
        position: absolute;
        margin-top: 1px;
        font: 10px;
        bottom: 100%;
      }

      // .dropdown:hover .dropbtn {
      //  /*   background-color: #3e8e41;*/
      // }

      &.footer-links-content {
        ul {
          li {
            &:not(:last-of-type) {
              margin-bottom: 15px;
            }
          }
        }
        @media (min-width: $mobile_lg) {
          ul {
            margin-left: 30px;
          }
        }
      }

      // &.hidden-mob {
      //   @media (max-width: $mobile_lg) {
      //     display: none;
      //   }
      // }

      &.hidden-desk {
        @media (min-width: $mobile_lg) {
          display: none;
        }
      }
    }
  }
}
