@import '../../../static/sass/media';

.prodmobileimgs-container {

    @media (min-width: $tablet) {
        display: none;
    }

    .prodmobileimgs-content {
        position: relative;
        border: 1px solid var(--color-grey-E2);
        width: 100%;
        height: 340px;

        .prodmobileimgs-car-container {
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .mobimg-container {
                flex-basis: 100%;
                min-width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 300ms ease;

                img {
                    max-height: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }

        .prodmobileimgs-total-container {
            position: absolute;
            bottom: 0;
            right: 0;
            background: var(--color-white-f9);
            padding: 6px 12px 5px 13px;

            span {
                color: var(--color-grey-78);
                font-size: 15px;
            }
        }

        .prodmobileimgs-buttons-container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: space-between;
            width: 105%;
            margin-left: -2.5%;

            button {
                background: var(--color-white-fa);

                &:nth-child(1) {
                    transform: rotate(180deg);
                }
            }
        }


    }
}