@import '../../../static/sass/media';

// FONDOS
.bg-transparent {
    background: var(--color-transparent)  !important;
}

.bg-green-light {
    background: var(--color-green-light) !important;
}

.bg-green-strong {
    background: var(--color-green-strong) !important;
}

.bg-green-dark {
    background: var(--color-green-dark);
}

.bg-yellow-light {
    background: var(--color-yellow-light) !important;
}

.bg-green-lighter {
    background: var(--color-green-lighter);
}

.bg-red-light {
    background: var(--color-red-light);
}

.bg-orange-light {
    background: var(--color-orange-light) !important;
}

.bg-white-primary {
    background: var(--color-white-primary);
}

.bg-purple-dark {
    background: var(--color-purple-dark);
}

.bg-grey-mediumlight {
    background: var(--color-grey-mediumlight);
}

.bg-grey-primarylight {
    background: var(--color-grey-primarylight);
}

.bg-grey-primary {
    background: var(--color-grey-primary);
}

.bg-black {
    background: black;
}

.bg-dodger-blue {
    background: var(--color-dodger-blue);
}
.bg-purple {
    background-color: var(--color-purple);
}
.bg-yellow {
    background-color: var(--color-yellow);
}

// COLORS

.color-green-light {
    color: var(--color-green-light);
}

.color-green-strong {
    color: var(--color-green-strong);
}

.color-green-dark {
    color: var(--color-green-dark);
}

.color-white-primary {
    color: var(--color-white-primary);
}

.color-dark {
    color: var(--color-dark);
}

.color-dark-primary {
    color: var(--color-dark-primary);
}
.color-dark-secondary {
    color: var(--color-dark-secondary);
}

.color-grey-light {
    color: var(--color-grey-light);
}

.color-grey-primary {
    color: var(--color-grey-primary);
}

.color-purple-dark {
    color: var(--color-purple-dark);
}

.color-yellow-light {
    color: var(--color-yellow-light);
}

.color-orange-light {
    color: var(--color-orange-light);
}

.color-dodger-blue {
    color: var(--color-dodger-blue);
}

.color-green-lighter {
    color: var(--color-green-lighter);
}

.color-blue-dark{
    color: var(--color-blue-dark);
}

// BORDERS
.border-green-strong {
    border: 1px solid var(--color-green-strong);
}

.border-green-dark {
    border: 1px solid var(--color-green-dark);
}

.border-white-primary {
    border: 1px solid var(--color-white-primary);
}

.border-purple-dark {
    border: 1px solid var(--color-purple-dark);
}

.border-grey-light {
    border: 1px solid var(--color-grey-light);
}

.border-green-light {
    border: 1px solid var(--color-green-light);
}

.border-dark {
    border: 1px solid var(--color-dark-secondary);
}

// SVG

.fill-white-primary {
    fill: var(--color-white-primary);
}

// AFTER
.after-purple {
    &::after {
        background: var(--color-purple-dark);
    }
}

.after-green-dark {
    &::after {
        background: var(--color-green-dark);
    }
}



// FONTS

.bold {
    font-weight: var(--font-bold);
}

.medium {
    font-weight: var(--font-medium);
}


// SECTION SPACE
.section-space {
    margin-bottom: 40px;

    @media (min-width: $mobile_lg) {
        margin-bottom: 60px;
    }

    @media (min-width: $tablet) {
        margin-bottom: 80px;
    }
}

// PAGE PADDING
.page-padding {
    // width: 1030px;
    width: var(--page-padding);
    margin-left: auto;
    margin-right: auto;
}

// FLEX
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-jc {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    justify-content: center;
}


// MARGIN
.mt-3 {
    margin-top: 30px;
}

// ARROWS
.arrow {
    border: solid var(--color-grey-mediumdark);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;

    &.white {
        border: solid var(--color-white-primary);
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }

    &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    &.bottom {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}


// CROSS
.cross {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 19px;
        top: 3px;
        height: 33px;
        width: 2px;
        background-color: var(--color-dark-primary);
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}


// ARROW LEFT
.arrow-left {

    &::after {
        content: '';
        position: absolute;
        left: -14px;
        top: 23%;
        border: solid var(--color-white-primary);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 2.5px;
        transform: rotate(135deg);

        @media (min-width: $tablet) {
            top: 26%;
        }

    }
}


.marginr-auto {
    margin-right: auto;
    max-width: 250px;
}

.mb-100 {
    margin-bottom: 100px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-50 {
    margin-bottom: 50px;
}


.animate-filter {
    animation: filteranimation 1.5s infinite alternate-reverse;
}

@keyframes filteranimation {
    from {
        filter: brightness(1);
    }

    to {
        filter: brightness(1.2);
    }
}

.no-m-3 {
    &:nth-child(3n) {
        margin-right: 0px;
    }
}

.no-m-4 {
    &:nth-child(4n) {
        margin-right: 0px;
    }
}

.popmessage {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: transform 200ms ease;
    width: 200px;
    background: var(--color-grey-E2);
    color: var(--color-dark-primary);
    height: 50px;
    top: 0px;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-bold);
    text-align: center;


    &.active {
        transform: translate(-50%, 20%);
    }
}

.hidden-desk {
    @media (min-width: $tablet) {
        display: none;
    }
}

.hidden-mob {
    @media (max-width: $tablet) {
        display: none;
    }
}
