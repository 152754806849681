@import '../../../static/sass/media';

.icategorias-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 4;

    @media (min-width: $tablet) {
        flex-wrap: nowrap;
    }

    .icategorias-item-container {
        flex-basis: 50%;
        width: 50%;

        @media (min-width: $tablet) {
            flex-basis: 25%;
            width: unset;
        }

        @media (max-width: $tablet) {
            &:nth-of-type(odd) {
                .icategorias-item-content {
                    padding-left: var(--container-padding);
                }
            }
        }

        a {
            cursor: unset;
        }

        // Coloración
        &:nth-child(1) {
            background: #F0923D;
            button {
                &::after {
                    background: #E7661C;
                }
            }

        }

        // Complementos
        &:nth-child(2) {
            background: #27ABE4;

            button {
                &::after {
                    background: #0069C8;
                }
            }
        }

        // Kits
        &:nth-child(3) {
            background: #FCD116;

            button {
                &::after {
                    background: #FAC019;
                }
            }
        }

        // Cuidados del cabello
        &:nth-child(4) {
            background: #70A182;

            button {
                &::after {
                    background: #275C38;
                }
            }
        }

        .icategorias-item-content {
            background: inherit;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 10%;

            padding-left: var(--container-padding);

            @media (min-width: $tablet) {
                width: fit-content;
                margin: 0 auto;
                text-align: center;
                padding: 40px 40px 70px;
                display: block;
            }

            @media (min-width: $desktop_md) {
                padding: 40px 40px 70px;
            }

            .icategorias-text-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;

                @media (min-width: $mobile_lg) {
                    height: 100px;
                }

                @media (min-width: $tablet) {
                    height: 110px;

                }

                @media (min-width: $desktop_md) {
                    height: 120px;
                }

                h5 {
                    font-size: 14px;
                    font-weight: var(--font-semibold);
                    cursor: pointer;

                    @media (min-width: $mobile_xs) {
                        font-size: 16.5px;
                    }

                    @media (min-width: $mobile_lg) {
                        font-size: 22px;
                    }

                    @media (min-width: $tablet) {
                        font-size: 28px;
                        font-weight: var(--font-bold);
                    }


                    @media (min-width: $desktop_md) {
                        font-size: 30px;
                        // margin-bottom: 45px;
                    }
                }

            }

            button {
                @media (max-width: $tablet) {
                    display: none;
                }
            }

            .icategorias-arrow-container {
                cursor: pointer;

                @media (min-width: $tablet) {
                    display: none;
                }

                svg {
                    height: 14px;
                    fill: white;

                    @media (min-width: $mobile_lg) {
                        height: 20px;
                    }
                }
            }
        }
    }

    .col-2 {
        flex-basis: 100%;
        @media (min-width: $tablet) {
            flex-basis: 50%;
            width: unset;
        }
    }
    .col-3 {
        flex-basis: 100%;
        @media (min-width: $tablet) {
            flex-basis: 33.33%;
            width: unset;
        }
    }
}