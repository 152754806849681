@import '../../../static/sass/media';

.iproductos-container {
    // margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    height: 274px;


    @media (max-width: $tablet) {
        margin-bottom: 30px;
    }

    @media (min-width: $tablet) {
        height: 470px;
        // margin-bottom: 110px;
    }

    @media (min-width: $tablet) and (max-width: $desktop_md) {
        width: 1040px !important;
    }

    @media (min-width: $desktop_md) {
        height: 570px;
        // margin-bottom: 130px;
    }

    .iproductos-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // overflow-x: hidden;

        @media (max-width: $tablet) {
            max-width: 680px;
            margin: 0 auto;
        }


        &>div {
            margin-right: 10px;

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    .iproductos-carbuttons-container {
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);

        @media (min-width: $tablet) {
            display: none;
        }

    }
}

.iproductos-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    @media (min-width: $tablet) {
        display: none;
    }

}