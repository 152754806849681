.chatactive-container {
    position: fixed;
    top: 150px;
    right: 150px;
    z-index: 5;
    width: 290px;

    .chatactive-title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba($color: white, $alpha: .85);
        padding: 5px 0px;

        .chatactive-person-container {
            display: flex;
            align-items: center;

            .picture-container {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: var(--color-grey-medium);
                border: 1px solid var(--color-grey-light);
                margin-right: 10px;
                margin-left: 20px;
            }

            span {
                font-size: 13px;
            }
        }

        .chatactive-options-container {
            padding: 10px 20px;
            cursor: pointer;

            .option-item {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                margin-bottom: 3px;
                background: var(--color-green-dark);

                &:last-of-type {
                    margin-bottom: 0px;
                }

            }
        }
    }

    .chatactive-content-container {
        background: var(--color-white-primary);
        padding: 10px 20px 20px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
        max-height: 300px;
        overflow-y: scroll;

        .comment {
            margin-bottom: 10px;
            padding: 20px;
            max-width: 80%;
        }

        .chatactive-usercomment {
            background: var(--color-green-dark);
            color: var(--color-white-primary);
            border-radius: 10px 0px 10px 10px;
            margin-left: auto;

            p {}
        }

        .chatactive-helpcomment {
            color: var(--color-green-dark);
            background: var(--color-green-thin);
            border-radius: 0px 10px 10px 10px;

            p {}
        }

        .chatactive-date {
            font-weight: var(--font-bold);
            margin-left: 40px;
            color: var(--color-green-dark);

            span {}
        }
    }

    .chatactive-send-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--color-white-primary);
        padding: 10px 15px;
        border-top: 1px solid var(--color-grey-thiner);

        input {
            margin-left: 25px;
            border: none;
            font-size: 11px;
            width: 100%;
            color: var(--color-grey-medium);

            &::placeholder {
                color: var(--color-grey-medium);
            }
        }

        img {
            cursor: pointer;
            height: 20px;
        }
    }

    .chatactive-close-btn {
        background: rgba($color: #83B7C4, $alpha: .8);
        border-radius: 100px 10px 100px 100px;
        width: 40px;
        height: 40px;
        margin-left: auto;
        margin-top: 5px;
        cursor: pointer;

        .chatactive-cross {
            font-size: 30px;
            color: #FFF;
            text-align: center;
        }
    }
}