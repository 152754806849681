@import '../../../static/sass/media';

.itutoriales-container {
    overflow-x: hidden;

    .itutotiales-title-container {
        text-align: center;
        font-weight: var(--font-bold);
        margin-bottom: 40px;

        h3 {
            font-size: 30px;
            color: var(--color-dark-secondary);
            margin-bottom: 40px;
        }

        button {
            transition: color var(--transition);

            &:hover {
                color: white;
            }

            &::after {
                background: var(--color-purple-dark);
            }
        }
    }

    .itutoriales-videos-container {
        position: relative;
        width: 100vw;
        overflow-x: hidden;


        .itutoriales-videos-content {
            display: flex;
            align-items: center;
            height: 55vw;

            @media (min-width: $mobile_lg) {
                height: 32.5vw;
            }

            .videos-item-container {
                transition: transform 1s ease, min-width 1s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                height: unset;
                height: 100%;
                min-width: 25%;

                @media (min-width: $mobile_lg) {}

                img {
                    object-fit: cover;
                    max-height: 100%;
                    max-width: 100%;
                }

                &.active {
                    z-index: 1;
                    min-width: 50%;

                }
            }

        }

        .itutoriales-buttons-container {
            position: absolute;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //padding: 0px 50px;
            height: 100%;
            // z-index: 2;

            @media (min-width: $tablet) {
                padding: 0px 130px;
            }

            @media (min-width: $desktop_md) {
                padding: 0px 365px;
            }

            // background: red;
            button {
                &:nth-child(1) {
                    transform: rotate(180deg);
                }
            }
        }

        .itutoriales-play-button {
            position: absolute;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 2px solid var(--color-white-primary);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;

            @media (min-width: $tablet) {
                width: 70px;
                height: 70px;
            }

            .play-button {
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 11.5px solid var(--color-white-primary);
                transform: rotate(90deg);
                margin-left: 4px;

                @media (min-width: $tablet) {
                    border-left-width: 12.5px;
                    border-right-width: 12.5px;
                    border-bottom-width: 20px;
                }
            }

            &.hide {
                opacity: 0;
            }
        }
    }

    .itutoriales-button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        @media (min-width: $tablet) {
            display: none;
        }
    }

}