.container {
  .imgContainer {
    position: relative;

    img {
      width: 100%;
      cursor: pointer;
    }

    .btnContainer {
      position: absolute;
      width: fit-content;
      top: 75%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
