@import '../../../static/sass/media';

.loadingproducts-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 60px;
    justify-content: center;

    @media (max-width: $tablet) {
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 40px;
    }

    @media (min-width: $mobile_lg) {
        flex-wrap: nowrap;
    }


    .loadingproduct-container {
        flex-basis: 144px;
        margin-right: 10px;
        height: 180px;
        margin-bottom: 30px;
        cursor: pointer;
        color: var(--color-grey-C8);

        @media (max-width: $mobile_lg) {
            margin-bottom: 50px;

            &:nth-child(2n) {
                margin-right: 0px;
            }
        }

        @media (min-width: $mobile_lg) {
            flex-basis: 160px;
            height: 211px;
        }

        @media (min-width: $tablet) {
            flex-basis: 252px;
            margin-right: 15px;
            height: 290px;

            @media (max-width: 1440px) and (min-width: 1080px) {
                height: 24vw;
            }

            &:nth-last-child(-n+3) {
                margin-bottom: 0px;
            }

        }

        @media (min-width: $desktop_md) {
            height: 340px;
            flex-basis: 323px;
            margin-right: 18px;
        }

        .loadingproduct-fakeimg-container {
            width: 100%;
            height: 90%;
            background: currentColor;

        }

        .loadingproduct-faketext-container {
            margin-top: 10px;
            width: 100%;
            height: 30px;
            background: currentColor;
            border-radius: 130px;
        }
    }
}

@keyframes filteranimation {
    from {
        filter: brightness(1);
    }

    to {
        filter: brightness(1.2);
    }
}