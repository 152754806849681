.container {
  width: 100%;
  min-height: 800px;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (min-width: 1080px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  padding-top: 50px;
  padding-bottom: 50px;
}

.collapsibleBoxContainer {
  margin-bottom: 20px;

  .title {
    font-size: 1.5em;
  }

  .bar {
    display: block;
    width: 100%;
    border-bottom: 2px #6C625A solid;
    margin-top: 15px;
  }

  .collapsed {
    margin-top: 20px;
    transition: all 0.5s ease-out;
    max-height: 0px;
    opacity: 0;
    pointer-events: none;
  }

  .collapsedLast {
    max-height: initial;
  }

  .uncollapsed {
    margin-top: 20px;
    transition: all 0.5s ease-out;
    max-height: initial;
    opacity: 1;
  }

  p {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 10px;
  }

  ul {
    list-style-position: inside;
  }

  ol {
    list-style-position: inside;
  }
}
