@import '../../../static/sass/media';

.inp-container {
    position: relative;
    font-size: 12px;

    @media (min-width: $tablet) {
        font-size: 17px;
    }

    input {
        border: none;
        border-bottom: 1px solid var(--color-grey-mediumdark);
        font-size: inherit;
        width: 100%;
        padding-bottom: 5px;
    }

    label {
        position: absolute;
        bottom: 6.21px;
        left: 0px;
        color: var(--color-dark-primary);
        transition: transform 200ms ease, color 200ms ease;

        @media (min-width: $tablet) {
            bottom: 10px;
        }
    }

    &:focus-within label,
    input:not(:placeholder-shown)+label {
        transform: translate(-10%, -14px) scale(.8);
        color: var(--color-green-dark);


        @media (min-width: $tablet) {
            transform: translate(-10%, -18px) scale(.8);
        }
    }
}