@import '../../../static/sass/media';

.prodtut-container {
    border-bottom: 1px solid var(--color-grey-mediumdark);
    padding-bottom: 33px;
    margin-bottom: 40px;
    max-width: 100%;

    @media (min-width: $desktop_md) {
        max-width: 60%;
    }

    @media (min-width: $tablet) {
        padding-bottom: 50px;
        margin-bottom: 60px;
    }

    .prodtut-content {

        .prodtut-top {
            margin-bottom: 40px;

            @media (min-width: $tablet) {
                margin-bottom: 40px;
            }

            h5 {
                font-size: 20px;
                font-weight: var(--font-medium);
                color: var(--color-dark-primary);

                @media (min-width: $tablet) {
                    font-size: 25px;
                }

                span {
                    font-weight: var(--font-regular);
                }
            }

        }

        .prodtut-main {

            .prodtut-video-container {
                position: relative;
                min-height: 178px;
                max-height: 480px;
                width: 100%;
                height: 40vw;

                .video {
                    width: 100%;
                    height: 100%;
                    background: #333;
                    position: relative;

                    img {
                        width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                    }

                    .iframe-container {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        top: 0px;
                    }
                }
            }
        }
    }
}
