@import '../../../static/sass/media';

.searchmgs-container {
    position: absolute;
    left: 23.3%;

    @media(max-width: $tablet) {
        width: 100%;
        left: 0%;
    }


    span {
        @media(max-width: $tablet) {
            width: 500px;
            margin: 0 auto;
            display: block;
        }
    }
}