@import '../../../static/sass/media';

html {

    --mobile-padding: 30px;

    --container-padding: 30px;

    @media (min-width: $tablet) {
        --container-padding: 100px;
    }

    --title-mb: 30px;

    @media (min-width: $tablet) {
        --title-mb: 32px;
    }

    @media (min-width: $desktop_sm) {
        --title-mb: 40px;
    }

    @media (min-width: $desktop_md) {
        --title-mb: 45px;
    }

    --page-padding: 90vw;

    @media (min-width: $tablet) {
        // --page-padding: 1030px;
        --page-padding: calc(100vw - (var(--container-padding) * 2));
    }

    @media (min-width: $desktop_md) {
        --page-padding: 1250px;
    }

    --transition: 200ms ease;

    --font-regular: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;

    --font-family-primary: 'Raleway',
    sans-serif;
    --font-family-secondary: 'Roboto',
    sans-serif;

    --color-dark: #000;
    --color-dark-primary: #2F2F2F;
    --color-dark-secondary: #212121;

    --color-grey-primary: #6C625A;
    --color-grey-primarylight: #E2E0DE;
    --color-grey-light: #707070;
    --color-grey-medium: #C2C2C2;
    --color-grey-mediumlight: #F5F5F5;
    --color-grey-mediumdark: #A1A1A1;
    --color-grey-thin: #EFEFEF;
    --color-grey-thiner: #E5E5E5;
    --color-grey-dark: #565656;

    --color-grey-78: #787878;
    --color-grey-E6: #E6E6E6;
    --color-grey-C8: #C8C8C8;
    --color-grey-E4: #E4E4E4;
    --color-grey-F5: #F5F5F5;
    --color-grey-E2: #E2E2E2;
    --color-grey-B8: #B8B8B8;

    --color-white-primary: #fff;
    --color-white-light: #ccc;
    --color-white-fa: #FAFAFA;
    --color-white-f9: #F9F9F9;
    --color-white-f7:#F7F7F7;

    --color-purple-dark: #59058D;

    --color-red-light: #E00747;

    --color-yellow: #f9c007;
    --color-yellow-light: #FCD116;
    --color-purple: #351c75;
    --color-orange-light: #EE7F1C;

    --color-green-lighter: #6c8f6d;
    --color-green-light: #58916D;
    --color-green-thin: #D7EAEE;
    --color-green-lighter: #83B7C4;
    --color-green-strong: #029DE0;
    --color-green-dark: #2B8FA7;
    --color-transparent: rgba(158, 160, 160, 0);
    --color-dodger-blue: #0094cf;
    --color-blue-dark: #0b5394;
}
