@import "../../../../static/sass/media";

.menu-productos-container {
  position: absolute;
  width: 100%;
  background: var(--color-white-primary);
  left: 0%;
  top: 100%;
  z-index: 10;
  font-family: var(--font-family-secondary);

  @media (max-width: $tablet) {
    display: none;
  }

  .menu-productos-content {
    padding: 30px var(--container-padding);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--color-grey-primary);

    .menu-list-container {
      // flex-basis: 14%;
      max-width: 14%;
      // margin-right: 50px;

      h5 {
        text-transform: uppercase;
        font-size: 15px;
        margin-bottom: 22px;

        @media (max-width: $desktop_sm) {
          font-size: 14px;
        }

        a {
          font-weight: var(--font-bold);
          color: var(--color-dark-primary);
        }
      }

      ul {
        li {
          max-width: 165px;
          margin-bottom: 14px;
          text-transform: capitalize;
          // max-width: 190px;

          a,
          span {
            font-size: 12px;
            line-height: 1;

            @media (max-width: $desktop_sm) {
              font-size: 11px;
            }
          }

          &:last-of-type {
            margin-bottom: 0px;
          }

          &.title {
            a,
            span {
              font-weight: var(--font-bold);
              color: var(--color-dark-primary);
            }
          }

          &.color {
            position: relative;

            &::before {
              content: "";
              width: 3px;
              height: 13px;
              position: absolute;
              left: -8px;
              top: 4px;
            }

            &.red {
              &::before {
                background: var(--color-red-light);
              }
            }

            &.yellow {
              &::before {
                background: var(--color-orange-light);
              }
            }

            &.green-light {
              &::before {
                background: var(--color-green-light);
              }
            }

            &.green-strong {
              &::before {
                background: var(--color-green-strong);
              }
            }
          }
        }
      }
    }

    .menu-img-container {
      flex-basis: 33%;
      display: flex;
      align-items: flex-end;
      background: url("../../../../static/img/banner/MenuProductos.jpg") no-repeat;
      width: 370px;
      height: 350px;
      padding: 48px 20px 70px;
      background-position: -11px 40%;
      background-size: 450px;

      svg {
        width: 25px;
      }
    }

    @media (max-width: $desktop_md) {
      .menu-img-container {
        flex-basis: 33%;
        display: flex;
        align-items: flex-end;
        background: url("../../../../static/img/banner/MenuProductos.jpg") no-repeat;
        width: 370px;
        height: 350px;
        padding: 48px 20px 80px;
        background-position: -15px 40%;
        background-size: 430px;
  
        svg {
          width: 25px;
        }
      }
    }
    @media (max-width: $desktop_sm) {
      .menu-img-container {
        flex-basis: 33%;
        display: flex;
        align-items: flex-end;
        background: url("../../../../static/img/banner/MenuProductos.jpg") no-repeat;
        width: 200px;
        height: 350px;
        padding: 48px 20px 140px;
        background-position: -13px 10%;
        background-size: 370px;
  
        svg {
          width: 25px;
        }
      }
    }



    .bg-img-peru {
      background: url("../../../../static/img/banner/bg_img_pe.jpg") no-repeat;
      background-position: 0%;
      background-size: 100%;
      .button-peru {
        top: -5px;
      }
    }

    .bg-img-bol {
      background: url("../../../../static/img/banner/bg_productos_bol.jpg")
        no-repeat;
      background-position: 0%;
      background-size: 100%;
    }
    .bg-img-ec {
      background: url("../../../../static/img/banner/BG_PRODUCTOS_EC.jpg")
        no-repeat;
      background-position: 0%;
      background-size: 100%;
    }
    .bg-img-cl {
      background: url("../../../../static/img/banner/bg-img-cl.jpg") no-repeat;
      background-position: 0%;
      background-size: 100%;
    }
    .bg-img-py {
      background: url("../../../../static/img/banner/bg-img-py.jpg") no-repeat;
      background-position: 0%;
      background-size: 100%;
    }
  }
}
