@import '../../../../static/sass/media';

.menu-mobile-container {
    position: fixed;
    z-index: 13;
    transition: transform 300ms ease;
    transform: translateX(-253px);
    top: 0px;

    @media (min-width: $tablet) {
        display: none;
    }

    &.active {
        transform: translateX(0px);
    }

    .menu-mobile-content {
        width: 253px;
        background: white;


        .menu-mobile-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px var(--mobile-padding);
            border-bottom: 2px solid var(--color-grey-primary);
            height: 59px;

            svg {
                height: 22px;
            }

            .menu-cross-container {
                position: relative;
                left: -27px;

                &::before,
                &::after {
                    height: 20px;
                    top: -10px;
                    right: calc(var(--mobile-padding) - 23px);
                }
            }
        }

        .menu-mobile-list-container {
            width: 100%;
            height: 100%;
            height: calc(100vh - 59px);
            padding-bottom: 40px;
            overflow-y: scroll;
        }
    }
}