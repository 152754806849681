* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

html,
body {
    font-family: var(--font-family-primary);
    font-weight: var(--font-regular);
    color: var(--color-grey-dark);
}


a {
    text-decoration: none;
    color: var(--color-grey-dark);
}

p {
    line-height: 1.8;
}

input {
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--font-family-primary);

    &:focus {
        outline: none;
    }
}