@import '../../../static/sass/media';

.loadingmenu-container {
    color: var(--color-grey-C8);
    height: 400px;

    @media (max-width: $tablet) {
        display: none;
    }

    .loadingmenu-content {
        display: flex;
        padding-top: 30px;

        .loadingmenu__left {
            flex-basis: 75%;
            display: flex;
            justify-content: space-between;
            padding: 0px 30px 30px;

            .loading-column {
                margin-right: 20px;
                flex-basis: 25%;

                .loading-li {
                    width: 100%;
                    height: 20px;
                    margin-bottom: 10px;
                    background: currentColor;
                }

            }
        }

        .loadingmenu__right {
            flex-basis: 20%;

            .loadingmenu-img {
                width: 100%;
                height: 150px;
                background: currentColor;
            }
        }
    }
}