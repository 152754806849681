@import '../../../../static/sass/media';


.video-container {

    .video-content {

        .video-title-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--color-white-f9);
            padding: 13px 18px 13px 9px;
            position: relative;

            @media (max-width: $tablet) {
                &::before {
                    content: '';
                    position: absolute;
                    height: 30px;
                    width: 1px;
                    background: var(--color-grey-light);
                    right: 50px;
                }
            }

            @media (min-width: $tablet) {
                padding: 10px 15px;
            }

            @media (min-width: $desktop_md) {
                padding: 15px 20px;
            }

            h6 {
                font-size: 12px;
                color: var(--color-dark-primary);

                @media (min-width: $tablet) {
                    font-size: 15px;
                }

                @media (min-width: $desktop_md) {
                    font-size: 19px;
                }
            }

            img {
                height: 17px;
                cursor: pointer;

                @media (min-width: $tablet) {
                    height: 20px;
                }

                @media (min-width: $desktop_md) {
                    height: 24px;
                }
            }
        }

        .video-video-container {

            .video {
                height: 40vw;
                background: #333;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                @media (min-width: $tablet) {
                    height: 200px;
                }


                @media (min-width: $desktop_md) {
                    height: 250px;
                }

                img {
                    width: auto;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .video-color-div {
            height: 6px;
            width: 100%;

            @media (min-width: $tablet) {
                height: 8px;
            }

            @media (min-width: $desktop_md) {
                height: 10px;
            }
        }
    }
}