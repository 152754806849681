@import '../../../static/sass/media';

.maintut-container {
    width: 100%;
    margin-bottom: 100px;

    .maintut-content {
        padding-top: 15px;

        @media (min-width: $tablet) {
            padding-top: 50px;
            padding-left: 30px;
        }

        .maintut-title-containter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: var(--title-mb);

            h1 {
                color: var(--color-dark-secondary);
                font-size: 25px;
                max-width: 250px;

                @media (min-width: $tablet) {
                    font-size: 27px;
                    max-width: unset;
                }

                @media (min-width: $desktop_md) {
                    font-size: 35px;
                }
            }

            .title-links-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                span {
                    color: var(--color-grey-primary);
                    margin-right: 10px;
                    font-size: 15px;

                    @media (max-width: $tablet) {
                        display: none;
                    }

                    @media (min-width: $desktop_md) {
                        font-size: 20px;
                    }
                }

                .title-links-svg-container {
                    position: relative;

                    @media (max-width: $tablet) {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::before {
                            content: '';
                            position: absolute;
                            height: 30px;
                            width: 1px;
                            background: var(--color-grey-light);
                            left: -15px;
                        }
                    }

                    img {
                        width: 17px;
                        vertical-align: middle;

                        @media (min-width: $tablet) {
                            width: 23px;
                        }

                        @media (min-width: $desktop_md) {
                            width: 27px;
                        }
                    }
                }

            }
        }

        .maintut-yt-container {
            margin-bottom: 110px;

            @media (min-width: $tablet) {
                margin-bottom: 80px;
            }


            .yt-video-content {
                position: relative;



                .video {
                    width: 100%;
                    height: 40vw;
                    background: #333;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    @media (min-width: $tablet) {
                        height: 500px;
                    }

                    img {
                        max-width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .iframe-container {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                    }


                }


                .yt-controllers-container {
                    position: absolute;
                    width: 105%;
                    left: -2.5%;
                    top: 50%;
                    display: flex;
                    justify-content: space-between;
                    transform: translateY(-50%);

                    button {
                        background: var(--color-white-fa);

                        &:nth-child(1) {
                            transform: rotate(180deg);
                        }
                    }
                }

            }

            .yt-video-info {
                background: var(--color-white-f9);
                padding: 28px 5px;
                display: flex;
                // justify-content: space-between;
                position: relative;

                @media (min-width: $tablet) {
                    padding: 30px 58px 30px 10px;
                }

                @media (min-width: $desktop_md) {
                    padding: 40px 80px 40px 10px;
                }

                .yt-info-img-container {
                    flex-basis: 30%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;


                    @media (min-width: $tablet) {
                        flex-basis: 35%;
                    }

                    @media (min-width: $desktop_md) {}

                    img {
                        max-height: 95%;
                        height: auto;
                        max-width: 100%;


                    }

                }

                .yt-info-container {
                    padding-top: 10px;
                    flex-basis: 65%;

                    @media (min-width: $tablet) {
                        flex-basis: 75%;
                    }

                    p {
                        color: var(--color-dark-primary);
                        font-size: 12px;
                        line-height: 16px;

                        @media (min-width: $tablet) {
                            font-size: 13px;
                            //margin-bottom: 35px;
                        }


                        @media (min-width: $desktop_md) {
                            font-size: 17px;
                            line-height: 25px;
                        }
                    }

                    .yt-info-btns-container {
                        display: flex;

                        @media (max-width: $tablet) {
                            position: absolute;
                            width: 100vw;
                            justify-content: center;
                            bottom: -70px;
                            left: -5vw;
                            // transform: translateX(0%);
                        }

                        button {
                            &:nth-child(1) {
                                margin-right: 25px;
                            }
                        }
                    }
                }
            }
        }


        .maintut-videos-container {

            h5 {
                font-size: 18px;
                margin-bottom: var(--title-mb);
                color: var(--color-dark-primary);
                font-weight: var(--font-medium);
                text-align: center;

                @media (min-width: $tablet) {
                    font-size: 23px;
                    text-align: left;
                }

                @media (min-width: $desktop_md) {
                    font-size: 27px;
                }
            }

            .maintut-videos-content {

                @media (max-width: $tablet) {

                    &>div:not(:last-child) {
                        margin-bottom: 20px;
                    }
                }

                @media (min-width: $tablet) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 35px 20px;
                }

                @media (min-width: $desktop_md) {
                    grid-gap: 50px 25px;
                }
            }
        }
    }
}