@import '../../../static/sass/media';

.loadingtutoriales-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 15px;
    max-height: 420px;

    @media (max-width: $tablet) {
        max-height: 170vw;
        margin-bottom: 100px;
    }

    @media (min-width: $tablet) and (max-width: $desktop_sm) {
        max-height: 340px;
    }

    @media (min-width: $tablet) {
        margin-top: 30px;
        max-height: 540px;
    }

    @media (min-width: $desktop_md) {
        margin-top: 40px;
        max-height: 690px;
    }

    .loadingtutorial-container {
        flex-basis: 45%;
        height: 200px;


        @media (max-width: $tablet) {
            margin-bottom: 20px;
            flex-basis: 100%;
            height: 40vw;
        }

        @media (min-width: $tablet) and (max-width: $desktop_sm) {
            height: 160px;
        }

        @media (min-width: $tablet) {
            margin-right: 20px;
            flex-basis: 370px;
            height: 252px;

            &:nth-child(2n) {
                margin-right: 0px;
            }
        }

        @media (min-width: $desktop_md) {
            margin-right: 25px;
            flex-basis: 465px;
            height: 318px;
        }


        .loadingtutorial-fakeimg-container {
            background: var(--color-grey-C8);
            height: 100%;
            width: 100%;
        }
    }
}