@import '../../../static/sass/media';

.prodcontent-container {

    .prodcontent-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid var(--color-grey-mediumdark);
        color: var(--color-grey-primary);


        @media (max-width: $tablet) {
            height: 30px;
            margin-bottom: 20px;
        }

        @media (min-width: $tablet) {
            color: var(--color-dark-primary);
        }

        h5 {
            font-weight: var(--font-bold);
            font-size: 15px;

            @media (min-width: $tablet) {
                font-size: 20px;
            }

        }

        span {
            font-size: 40px;
            font-weight: var(--font-regular);
            color: var(--color-dark-primary);

        }
    }

    .prodcontent-content {

        &.descripcion {

            @media (min-width: $tablet) {
                position: relative;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background: white;
                    left: 0px;
                    width: 100%;
                    height: 20px;
                }

                &::before {
                    top: -1px;
                }

                &::after {
                    bottom: -1px;
                }
            }

        }

        @media (max-width: $tablet) {
            display: none;
        }

        @media (min-width: $tablet) {
            overflow: hidden;
            max-height: 0px;
            transition: max-height 500ms ease;
        }

        &.active {

            @media (max-width: $tablet) {
                display: block;
            }

            @media (min-width: $tablet) {
                max-height: 1100px;
            }

        }
    }
}