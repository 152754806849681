@import '../../../static/sass/media';


.button-container {
    border: none;
    background: transparent;
    color: var(--color-white-primary);
    font-family: var(--font-primary);
    cursor: pointer;
    transition: opacity 200ms ease;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    position: relative;
    font-size: 12px;
    // letter-spacing: 1px;
    // line-height: .99;

    @media (min-width: $mobile_lg) {
        font-size: 13px;
    }

    @media (min-width: $desktop_md) {
        font-size: 15px;
    }

    @media (min-width: $desktop_md) and (min-height: 650px) {
        font-size: 17px;
    }

    svg {
        fill: currentColor !important;
        stroke: currentColor;

        .arrow-path {
            fill: currentColor !important;
        }
    }

    .color {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-left: 5px;
        border: 1px solid rgb(0, 0, 0);
    }

    &:hover {
        opacity: .9;
    }

    &:focus {
        outline: none;
    }

    &.big {
        padding: 15px 25px;

        @media (min-width: $desktop_md) {
            padding: 18px 28px;
        }
    }

    &.categorias {
        padding: 13px 22px;

        @media (min-width: $desktop_md) {
            padding: 16px 26px;
        }
    }

    &.large {
        padding: 13px 15px;

        @media (min-width: $tablet) {
            padding: 10px 30px;
        }

        @media (min-width: $desktop_md) {
            padding: 13px 34px;
        }
    }

    &.large-sm {
        padding: 5px 15px;

        svg {
            margin-left: 10px;
            height: 30px;
        }
    }

    &.long {
        padding: 14px 40px;

        @media (min-width: $desktop_md) {
            padding: 16px 50px;
        }
    }

    &.fixed-size {
        width: 36vw;
        max-width: 146px;
        padding: 15px 0px;
        justify-content: center;

        @media (min-width: $desktop_md) {
            width: 140px;
            padding: 12px 0px;
        }

        @media (min-width: $desktop_md) {
            width: 170px;
            padding: 14px 0px;
        }
    }

    &.hover-invert {
        transition: font-weight var(--transition), border var(--transition);
        background: none;
        position: relative;
        z-index: 4;

        &::after {
            content: '';
            width: 0%;
            height: 100%;
            mix-blend-mode: multiply;
            position: absolute;
            right: 0;
            z-index: -1;
            transition: width var(--transition);
        }

        &:hover {
            font-weight: var(--font-bold);
            border: 1px solid transparent;
            // color: currentColor;
            color: var(--color-white-primary) !important;

            &::after {
                width: 100%;
                left: 0;
            }
        }
    }

    &.btn-delete {
        text-align: left !important;
        position: relative;
        padding: 12px 40px 12px 15px;

        @media (min-width: $desktop_md) {
            font-size: 13px;
        }

        &::after,
        &::before {
            content: '';
            right: 10%;
            position: absolute;
            height: 15px;
            width: 2px;
            background-color: var(--color-grey-primary);
        }

        &::after {
            transform: rotate(45deg);
        }

        &::before {
            transform: rotate(-45deg);
        }

    }


    &.btn-delete-sm {
        text-align: left !important;
        position: relative;
        padding: 7px;
        padding-right: 30px;
        font-size: 13px;

        @media (min-width: $desktop_md) {
            padding: 7px;
            padding-right: 30px;
            font-size: 14px;
        }

        &::after,
        &::before {
            content: '';
            right: 14px;
            top: 11px;
            position: absolute;
            height: 10px;
            width: 2px;
            background-color: var(--color-grey-primary);

        }

        &::after {
            transform: rotate(45deg);
        }

        &::before {
            transform: rotate(-45deg);
        }
    }

}