@import '../../../static/sass/media';

.lpdt-container {
    color: var(--color-grey-C8);
    margin: 80px 0px 100px;

    @media (min-width: $tablet) {
        min-height: 496px;
    }

    .lpdt-content {
        display: flex;
        justify-content: space-between;
        width: 80vw;
        margin: 0 auto;
        flex-wrap: wrap;

        .lpdt__left {
            flex-basis: 50%;

            @media (max-width: $mobile_lg) {
                min-width: 80vw;
            }

            .lpdt-img {
                width: 100%;
                background: currentColor;
                height: 320px;
            }
        }

        .lpdt__right {
            flex-basis: 45%;

            @media (max-width: $mobile_lg) {
                min-width: 89vw;
            }

            .lpdt-title {
                width: 40%;
                background: currentColor;
                height: 40px;
                margin: 40px 0px;
            }

            .lpdt-text {
                width: 90%;
                background: currentColor;
                height: 20px;
                margin-bottom: 10px;
            }

        }
    }
}