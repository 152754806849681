@import '../../../static/sass/media';

.note-p {
    font-size: 12px !important;
    margin-bottom: 20px !important;

    @media (min-width: $desktop_md) {
        font-size: 15px !important;
    }

    &:last-of-type {
        margin-bottom: 0px !important;
    }

    &>p {
        display: inline-block;
    }
}