.loadingti-container {
    height: 32.5vw;
    display: flex;
    align-items: center;
    transform: translateX(-5px);

    .loadingti-item {
        background: var(--color-grey-C8);

        &:nth-child(1),
        &:nth-child(3) {
            min-width: 25%;
            height: 20vw;
        }

        &:nth-child(2) {
            min-width: 50%;
            height: 31vw;
            margin: 0px 10px;
        }
    }
}