@import '../../../static/sass/media';

.filtros-list-container {
    margin-top: 20px;

    &:nth-of-type(2),
    &:nth-of-type(3) {
        margin-top: 30px;
    }

    h5 {
        text-transform: uppercase;
        font-weight: var(--font-bold);
        font-size: 15px;
        margin-bottom: 20px;

        @media (min-width: $desktop_md) {
            font-size: 16px;
            margin-bottom: 23px;
        }
    }

    ul {
        max-width: 80%;

        li {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: var(--font-medium);
            color: var(--color-grey-primary);
            text-transform: capitalize;
            cursor: pointer;

            @media (min-width: $desktop_md) {
                font-size: 16px;
                margin-bottom: 17px;
            }

            label {
                cursor: pointer;
            }

            &.colorcheckbox {
                input {
                    color: currentColor;
                    border: 1px solid currentColor;

                    &:checked {
                        background: currentColor !important;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0px;
            }

            input {
                cursor: pointer;
                background-color: white;
                border: 1px solid var(--color-grey-primary);
                padding: 7px;
                display: inline-block;
                position: relative;
                margin-right: 15px;
                transition: background var(--transition);
                color: var(--color-grey-primary);

                @media (min-width: $desktop_md) {
                    padding: 9px;
                    margin-right: 16px;
                }

                &.checked {
                    background: currentColor;

                    &::after {
                        content: '';
                        position: absolute;
                        // display: inline-block;
                        transform: rotate(45deg);
                        top: 2px;
                        left: 5px;
                        height: 7px;
                        width: 3px;
                        border-bottom: 1px solid white;
                        border-right: 1px solid white;

                        @media (min-width: $desktop_md) {
                            top: 3px;
                            left: 6px;
                            height: 8px;
                            width: 5px;
                            border-bottom: 1px solid white;
                            border-right: 1px solid white;
                        }
                    }
                }
            }
        }

        .color {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;

            &:nth-child(4n) {
                margin-right: 60px;

                @media (min-width: $desktop_md) {
                    margin-right: 100px;
                }
            }

            div {
                display: inline-block;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
}