.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;

  @media screen and (max-width: 1080px) {
    gap: 10px;
  }

  padding-top: 50px;
  padding-bottom: 50px;
}

@keyframes slideUp {
  from {
    transform: translateY(10%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes grow {
  from {
    width: 0%;
  }

  to {
    width: 40%;
  }
}

$anim-duration: 1s;

.storySectionContainer {
  display: inline-block;
  width: 35%;
  animation-name: slideUp;
  animation-duration: $anim-duration;

  @media screen and (max-width: 1080px) {
    width: 80%;
  }

  h1 {
    font-size: 3em;
    color: black;

    &::after {
      animation-name: grow;
      animation-duration: $anim-duration;
      content: '';
      display: block;
      width: 40%;
      height: 9px;
      background: #ee7f1c;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  h2 {
    color: black;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }
}
