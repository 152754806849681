@import '../../../static/sass/media';

.ver-mas-container {
    display: flex;
    justify-content: center;
    margin: 70px 0px 0px;

    @media (min-width: $tablet) {
        margin: 80px 0px 0px;
    }

    @media (min-width: $desktop_md) {
        margin: 100px 0px 0px;
    }

    &.tuts-page {
        @media (max-width: $tablet) {
            margin: 57px 0px 79px;
        }

        @media (min-width: $desktop_md) {
            margin: 70px 0px 110px;
        }
    }
}