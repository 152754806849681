@import '../../../static/sass/media';

.filtros-container {

    @media (min-width: $tablet) {
        margin-bottom: 80px;
    }

    @media (max-width: $tablet) {

        position: sticky;
        position: -webkit-sticky;
        background: white;
        z-index: 10;
        top: 54px;

        &.productos {
            top: 130px;
            z-index: 1;
            transform: translateY(-12px);

            @media (min-width: $mobile_xs) {
                top: 160px;
            }

            .categorias-mobile {
                transform: translateX(0px);
            }
        }
    }


    .mobile-label {
        color: var(--color-grey-primary);
        font-size: 12px;
        position: absolute;
        top: -74px;
        transform: translateX(-5vw);
        width: 100vw;
        background: white;
        z-index: 1;
        // margin-bottom: -100px;
        // top: 155px;
        padding-top: 25px;
        padding-bottom: 9px;
        height: 77px;


        @media (min-width: $mobile_xs) {
            padding-bottom: 16px;
            height: 99px;
            top: -95px;
        }

        @media (min-width: $tablet) {
            display: none;
        }

        span {
            display: block;
            margin-bottom: 10px;
            max-width: 500px;
            margin: 0 auto;
            padding-bottom: 9px;
            padding-left: 10px;
        }


        @media (max-width: $tablet) {
            .categorias-mobile {
                padding: 0px;
                position: relative !important;
            }
        }

    }


    .filtros-desktop-container {
        min-width: 240px;

        @media (max-width: $tablet) {
            display: none;
        }

        .filtros-title {
            display: inline-block;
            margin-top: 20px;
            font-weight: var(--font-bold);
            font-size: 12px;

            @media (min-width: $desktop_md) {
                margin-top: 25px;
                font-size: 13px;
            }
        }
    }

    .allproducts-container {
        position: relative;
        color: var(--color-grey-primary);
        max-width: 500px;
        margin: 0 auto;
        padding-left: 19px;
        padding-top: 10px;

        &::after {
            content: '';
            border: solid currentColor;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            transform: rotate(135deg);
            position: absolute;
            left: 4px;
            top: 17px;
        }

        a {
            font-size: 12px;
            color: currentColor;
        }
    }

}



.filtros-mobile-container {
    position: absolute;
    left: -5vw;
    display: flex;
    width: 100vw;
    z-index: 3;
    background: white;

    &.productos {
        top: 10px;
    }

    @media (min-width: $tablet) {
        display: none;
    }

    &>div {
        flex-basis: 50%;
    }
}