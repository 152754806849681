.select-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 30px;
    border: 1px solid var(--color-grey-E6);
    max-width: 250px;
    background: white;

    &:nth-of-type(1) {
        margin-left: auto;
    }

    &:nth-of-type(2) {
        margin-right: auto;
    }

    span {
        color: var(--color-grey-primary);
        font-weight: var(--font-medium);
        font-size: 13px;
    }

    .select-icon-container {
        width: 7px;
        height: 7px;

        .select-icon {
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-grey-primary);
            border-top: transparent;
            border-right: transparent;
            transform: rotate(-45deg) translateY(2px);
        }
    }
}