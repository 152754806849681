@import '../../../static/sass/media';

.page-content {
    position: relative;
    min-height: 170px;

    @media (min-width: $tablet) {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &>div {
            &:nth-child(1) {
                flex-basis: 26%;
            }

            &:nth-child(2) {
                flex-basis: 74%;
            }
        }
    }




}