.container {
  .smaller {
    font-size: 0.8em;
  }

  ol {
    list-style-position: inside;
    padding-top: 10px;
  }

  li {
    margin-bottom: 10px;
    line-height: 2;
  }

  b {
    text-align: center;
  }
}
