@import "../../../static/sass/media";

.navbar {
  position: relative;
  background: var(--color-white-primary);
  // width: 100vw;
  z-index: 12;
  padding-left: var(--container-padding);

  @media (max-width: $tablet) {
    box-shadow: 0px 2px 5px #00000029;
    position: fixed;
    width: 100vw;
    top: -2px;
    transform: translateZ(0);
    perspective: 1000;
  }

  li {
    list-style: none;
  }

  .logo {
    height: 45px;
    padding: 10px 0px;
    box-sizing: border-box;
    cursor: pointer;

    @media (max-width: $tablet) {
      display: flex;
      // align-items: center;
    }

    .mobile-hamburguer-container {
      margin-right: 30px;
      transform: translateY(3px);

      @media (min-width: $tablet) {
        display: none;
      }

      .mobile-hamburguer-content {
        width: 18px;

        & > div {
          height: 2px;
          background: var(--color-dark-primary);
        }

        .ham-1,
        .ham-2 {
          margin-bottom: 5px;
          width: 100%;
        }

        .ham-3 {
          width: 60%;
        }
      }
    }

    svg {
      max-height: 27px;
      min-width: 103px;

      @media (max-width: $tablet) {
        min-width: 84px;
      }
    }
  }

  .menu {
    // position: relative;

    ul {
      margin: 0;
      height: 100%;

      li {
        height: 70px;
        font-size: 16px;
        padding: 27px 5px;
        margin: 0px 27px;
        cursor: pointer;
        // position: relative;

        @media (max-width: $tablet) {
          height: unset;
          margin: 0px 0px;
          margin-right: 20px;
          padding: unset;
        }

        a {
          color: var(--color-dark-primary);
        }

        &:last-of-type {
          margin-right: 0px;
        }

        svg {
          fill: var(--color-dark-primary);
        }

        &.dark {
          padding: 27px 100px;

          &.hide-bol {
            background-color: var(--color-grey-primary);
            color: var(--color-white-primary);
          }

          background-color: var(--color-grey-primary);
          position: relative;

          @media (max-width: $tablet) {
            padding: 20px 10vw;
          }

          a {
            color: var(--color-white-primary);
          }

          &:hover {
            .eshop-dropdown {
              display: block;
            }
          }

          .eshop-dropdown {
            position: absolute;
            right: 0px;
            top: 70px;
            background-color: white;
            width: 100%;
            z-index: 20;
            display: none;

            @media (max-width: $tablet) {
              bottom: 58px;
              top: 58px;
            }

            .dropdown-item {
              margin: 0 auto;
              // padding: 10px 0px;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: background var(--transition);
              background-color: white;
              &:hover {
                background-color: var(--color-grey-thin);
              }

              &:nth-child(1) {
                border-bottom: 1px solid var(--color-grey-primary);
              }

              img {
                width: 70%;

                &.salonmarket-logo {
                  width: 31%;
                }
              }

              @media (max-width: $tablet) {
                height: 60px;
              }
            }
          }
        }

        &.navbar-item {
          // transition: font 1s ease;
          width: 80px;

          @media (max-width: $tablet) {
            display: none;
          }

          a {
            font-weight: var(--font-medium);

            &:hover {
              color: var(--color-dark-primary);
              font-weight: var(--font-bold);
            }
          }

          &.active {
            a {
              color: var(--color-dark-primary);
              font-weight: var(--font-bold);
            }
          }

          &.activetut {
            a {
              color: var(--color-purple-dark);
              font-weight: var(--font-bold);
            }
          }
        }

        .lupa-container {
          // position: relative;

          @media (min-width: $mobile_lg) {
            position: relative;
          }

          .busqueda-container {
            position: absolute;
            left: 0;
            top: 58px;
            display: flex;
            justify-content: space-between;
            box-shadow: 0px 2px 5px #00000029;
            width: 100vw;
            z-index: 21;
            padding: 15px 0px;
            background: white;
            border-top: 1px solid var(--color-grey-light);

            display: none;

            @media (min-width: $mobile_lg) {
              width: 300px;
              left: -280px;
              top: 28px;
            }

            @media (min-width: $tablet) {
              left: -240px;
              top: 43px;
            }

            form {
              display: flex;
              min-width: 263px;
            }

            .busqueda-svg-container {
              flex-basis: 13%;
              display: flex;
              border: none;
              background: none;
              cursor: pointer;

              &:focus {
                outline: none;
              }

              svg {
                height: 16px;
                margin-left: auto;
                fill: var(--color-grey-78);
              }
            }

            input {
              // background: white;
              border: none;
              text-align: left;
              min-width: 70%;
              margin-left: 10px;
              color: var(--color-grey-78);
            }

            .busqueda-cross-container {
              position: relative;
              top: -3px;
              flex-basis: 5%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-right: 40px;

              &::before,
              &::after {
                height: 18px;
                width: 1.5px;
              }
            }
          }
        }

        svg {
          width: 20px;
        }
      }
    }
  }
}

// &::after {
//     content: '';
//     position: absolute;
//     width: 0%;
//     height: 3px;
//     background: var(--color-grey-primary);
//     bottom: 0%;
//     left: 0;
//     transition: width 200ms ease;
// }

// &:hover {
//     &::after {
//         width: 100%;
//     }
// }
