@import '../../../static/sass/media';

.cont-banner-container {
    width: 100%;
    height: 300px;
    position: relative;

    img {
        max-height: 300px;
        position: relative;
        left: -500px;
    }

    @media (min-width: $tablet) {
        margin-top: 0px;
        height: 330px;

        img {
            max-height: none;
            position: relative;
            left: 0px;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        
    }

    .cont-banner-content {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding-top: 86px;

        @media (min-width: $tablet) {
            padding-top: 90px;
        }

        .cont-banner-align {
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .cont-banner-top {
                margin-bottom: 26px;

                @media (min-width: $tablet) {
                    font-size: 34px;
                }

                h1 {
                    font-size: 14px;
                    font-weight: var(--font-medium);

                    @media (min-width: $tablet) {
                        font-size: 16px;
                    }
                }
            }

            .cont-banner-bottom {
                h5 {
                    font-size: 22px;


                    @media (min-width: $tablet) {
                        font-size: 38px;
                    }
                }
            }
        }
    }
}