.container {
  padding: 50px;

  @media screen and (max-width: 1080px) {
    padding: 30px;
  }

  .textContainer {
    background-color: lightgrey;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 100px;

    p {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 1080px) {
      padding: 0px;
      background-color: initial;
    }
  }
}
