@import '../../../static/sass/media';

.cont-form-container {
    background: white;
    margin-bottom: -48px;

    li {
        list-style: none;
    }

    @media (min-width: $tablet) {
        min-height: 400px;
    }

    .cont-form-content {
        width: 90vw;
        margin: 0 auto;
        box-shadow: 0px 3px 6px #00000029;
        background: white;
        transform: translateY(-102px);
        max-width: 960px;

        @media (min-width: $tablet) {
            transform: translateY(-50px);
        }

        .cont-form-align {
            padding: 0px 20px;

            .cont-form-top {
                color: var(--color-green-dark);
                padding: 21px 0px 32.5px;

                @media (min-width: $tablet) {
                    padding: 30px 0px 60px;
                }

                .cont-title {
                    text-align: center;
                    margin-bottom: 18.5px;

                    @media (min-width: $tablet) {
                        margin-bottom: 25px;
                    }

                    p {
                        line-height: 1.4;
                        font-size: 15px;
                        font-weight: var(--font-medium);

                        @media (min-width: $tablet) {
                            font-size: 20px;
                        }

                    }
                }

                .cont-line-container {

                    .cont-actual-line {
                        width: 58px;
                        margin: 0 auto;
                        height: 3px;
                        background: currentColor;
                    }

                }
            }

            .cont-form-main {
                max-width: 610px;
                margin: 0 auto;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    &>div {
                        margin-bottom: 35px;

                        @media (min-width: $tablet) {
                            margin-bottom: 55px;
                        }

                        &:nth-child(1),
                        &:nth-child(6),
                        &:nth-child(9),
                        &:nth-child(10) {
                            flex-basis: 100%;
                        }

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(7),
                        &:nth-child(8) {
                            flex-basis: 48%;
                        }

                        &:nth-child(10) {
                            margin-bottom: 46px;
                        }

                        &:nth-child(11) {
                            flex-basis: 100%;
                            display: flex;
                            justify-content: center;
                            margin-bottom: 67px;
                        }
                    }

                    .form-select-container {
                        margin-top: -13px;
                        position: relative;


                        select {
                            appearance: none;
                            min-width: 100%;
                            border: none;
                            border-bottom: 1px solid var(--color-grey-mediumdark);
                            padding-bottom: 6.21px;
                            font-family: var(--font-family-primary);
                            color: var(--color-dark-primary);
                            font-size: 12px;

                            @media (min-width: $tablet) {
                                font-size: 17px;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .form-icon-container {
                            position: absolute;
                            right: 0px;
                            top: 5px;
                            color: var(--color-dark-primary);

                            .form-icon {
                                width: 6px;
                                height: 6px;
                                border-color: var(--color-dark-primary);
                                border-bottom: 2.5px solid;
                                border-right: 2.5px solid;
                                transform: rotate(45deg);
                            }
                        }
                    }

                }
            }
        }
    }
}