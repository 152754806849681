@import '../../static/sass/media';

.prodindv-links-container {
    display: flex;
    align-items: center;
    margin-top: 18px;

    @media (min-width: $tablet) {
        margin-top: 40px;
    }

    .mobile-link {

        @media (min-width: $tablet) {
            display: none;
        }

        span {
            margin-left: var(--container-padding);
            position: relative;
            font-weight: var(--font-semibold);
            font-size: 12px;
            color: var(--color-grey-primary);
            cursor: pointer;

            &::after {
                border-color: currentColor;
            }
        }

        .current-cat-banner {
            margin-top: 30px;

            h5 {
                color: var(--color-white-primary);
                padding: 2px 11px 3px;
                font-size: 14px;
                font-weight: var(--font-bold);
            }
        }

    }

    .desktop-links {
        @media (max-width: $tablet) {
            display: none;
        }

        &>* {
            margin-right: 15px;
        }

        a {
            color: var(--color-dark-primary);
            text-decoration: underline;
            font-weight: var(--font-medium);
            font-size: 15px;

            @media (min-width: $desktop_md) {
                font-size: 17px;
            }
        }

        .before {
            color: var(--color-grey-mediumdark);
            text-decoration: none;
        }
    }

}

.prodindv-content-container {

    @media (min-width: $tablet) {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

    }

    @media (min-width: $desktop_md) {
        margin-top: 50px;
    }

    .mobile-product-title {

        @media (min-width: $tablet) {
            display: none;
        }

        h4 {
            font-weight: var(--font-bold);
            font-size: 25px;
            color: var(--color-dark-secondary);
            max-width: 400px;
            margin: 31px 0px 27px 10px;
        }

    }
}

.prodindv-uso-container {
    background: var(--color-white-f9);
    padding: 23px 0px 43px;
    margin-bottom: 40px;

    @media (min-width: $tablet) {
        padding: 40px 0px 60px;
        margin-bottom: 60px;
    }

    @media (min-width: $desktop_md) {
        padding: 40px 0px 80px;
    }


}

.prodindv-relacionados-container {
    margin-bottom: 73px;

    @media (max-width: $tablet) {
        overflow: hidden;
        height: 330px;
    }


    @media (min-width: $tablet) {
        margin-bottom: 150px;
    }

    span {
        display: block;
        font-size: 20px;
        font-weight: var(--font-bold);
        text-align: center;
        margin-bottom: 29px;

        @media (min-width: $tablet) {
            font-size: 25px;
            margin-bottom: 40px;
        }
    }

    .relacionados-content {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;

        &>div {
            margin: 0 auto;
        }

        @media (max-width: $tablet) {
            max-width: 510px;
        }

        @media (min-width: $tablet) {
            width: 75%;
        }

        @media (min-width: $desktop_md) {
            width: 74%;
        }

    }

    .prodinv-carbuttons {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;


        @media (min-width: $tablet) {
            display: none;
        }
    }
}