@import '../../../static/sass/media';

.section-title {
    text-align: center;
    font-weight: var(--font-bold);
    margin-bottom: var(--title-mb);

    h3 {
        font-size: 18px;
        margin-bottom: 20px;
        color: var(--color-dark-secondary);

        @media (min-width: $mobile_lg) {
            font-size: 25px;
            margin-bottom: 30px;
        }

        @media (min-width: $tablet) {
            font-size: 35px;
            margin-bottom: 35px;
        }

        @media (min-width: $desktop_sm) {
            font-size: 35px;
            margin-bottom: 40px;
        }

        @media (min-width: $desktop_md) {
            font-size: 35px;
            margin-bottom: 45px;
        }
    }

    button {
        transition: color var(--transition);

        @media (max-width: $tablet) {
            display: none;
        }

        &:hover {
            color: white;
        }
    }
}