@import '../../../../static/sass/media';

.caritem2-container {
    overflow: hidden;

    .caritem2-info-container {

        .caritem2-tabs-container {
            display: flex;
            align-items: center;
            margin-bottom: 35px;

            @media (max-width: $tablet) {
                width: calc(100vw - var(--container-padding));
            }


            &>div {
                display: flex;
                align-items: center;
                max-width: 210px;
                min-width: 37%;

                @media (min-width: $tablet) {
                    min-width: 33%;
                }

                &:nth-child(1) {
                    min-width: 22%;

                    @media (min-width: $tablet) {
                        min-width: 20%;
                    }
                }
            }

            .tab-item {
                cursor: pointer;

                h6 {
                    color: var(--color-grey-mediumdark);
                    position: relative;
                    width: fit-content;
                    font-size: 13px;
                    font-weight: var(--font-regular);

                    @media (min-width: $tablet) {
                        font-size: 18px;
                    }

                    @media (min-width: $desktop_md) {
                        font-size: 20px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -10px;
                        left: 0px;
                        width: 100%;
                        height: 1px;
                        background: var(--color-grey-mediumdark);
                    }

                    p {
                        line-height: unset !important;
                    }
                }

                &.active {
                    h6 {
                        color: var(--color-dark-secondary);
                        font-weight: var(--font-bold);

                        &::after {
                            height: 2px;
                            background: var(--color-dark-secondary);
                        }
                    }
                }
            }

            span {
                font-size: 30px;
                color: var(--color-grey-light);
                margin: 0px 15px;

                @media (min-width: $tablet) {
                    margin: 0px 40px;
                }

                @media (min-width: $desktop_md) {
                    margin: 0px 40px;
                }
            }
        }
    }
}