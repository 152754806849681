@import "../../../static/sass/media";

.comprar-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #6c625a, $alpha: 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .comprar-content {
    background: var(--color-white-primary);
    width: 80vw;
    height: 227px;
    padding: 15px 30px;

    @media (max-width: $tablet) {
      max-width: 318px;
    }

    @media (min-width: $tablet) {
      width: 600px;
      height: 400px;
    }

    .comprar-cross-container {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      &.cross {
        @media (max-width: $tablet) {
          &::before,
          &::after {
            height: 16px;
            width: 1.5px;
          }
        }
      }
    }

    span {
      color: var(--color-dark-secondary);
      text-align: center;
      display: block;
      margin-top: 40px;
      font-size: 13.5px;

      @media (min-width: $tablet) {
        font-size: 22px;
        margin-top: 60px;
      }
    }

    .comprar-options-container {
      margin-top: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 40%;
      height: 20%;

      @media (min-width: $tablet) {
        margin-top: 80px;
      }

      img {
        height: 41px;
        cursor: pointer;

        @media (min-width: $tablet) {
          height: 90px;

          &.retailer-chile {
            height: 40px;
            width: 150px;
          }
          &.retailer-ecuador {
            height: 70px;
          }

          &.retailer-uruguay {
            height: 180px;
          }

          &.retailer-paraguay {
            height: 180px;
          }
        }

        &:nth-of-type(2) {
          height: 55px;

          @media (min-width: $tablet) {
            height: 100px;
          }
        }
      }

      .comprar-options-separator {
        margin: 0px 20px;
        background: var(--color-grey-B8);
        height: 35px;
        min-width: 1px;

        @media (min-width: $tablet) {
          margin: 0px 60px;
          background: var(--color-grey-mediumdark);
          height: 50px;
        }

        &:last-of-type {
          display: none;
        }
      }
    }
  }
}
