@import '../../../static/sass/media';

// Customizar algunas cosas para que se vea mejor
.react-3d-carousel .slider-container .slider-left div {
  color: black !important;
  border: none !important;
}

.react-3d-carousel .slider-container .slider-right div {
  color: black !important;
  border: none !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}

// Slide izquierda: desactivar pointer-events para no reproducir accidentalmente, y ocultar título
// de tutorial
.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content {
  pointer-events: none !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.preactive .slider-single-content .prodtut-name {
  display: none !important;
}

// Slide derecha: Idem
.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content {
  pointer-events: none !important;
}

.react-3d-carousel .slider-container .slider-content .slider-single.proactive .slider-single-content .prodtut-name {
  display: none !important;
}
