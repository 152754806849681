@import '../../../static/sass/media';

.prodindv-info-container {
    flex: 1;

    .info-firstpart-container {
        margin-bottom: 38px;

        @media (max-width: $tablet) {
            margin-top: 31px;
        }

        @media (min-width: $tablet) {
            margin-bottom: 50px;
        }

        h1 {
            margin-bottom: 30px;
            font-weight: var(--font-bold);
            font-size: 35px;
            color: var(--color-dark-secondary);
            max-width: 400px;

            @media (max-width: $tablet) {
                display: none;
            }
        }
    }

    .prodindv-description-container {
        overflow-y: scroll;
        color: var(--color-dark-secondary);
        padding-bottom: 20px;
        font-size: 16px;

        @media (min-width: $tablet) {
            padding: 20px 0px;
            max-height: 200px;
        }
    }

    .prodinv-colores-container {

        max-width: 625px;

        span {
            color: var(--color-green-light);
            display: block;
            font-size: 15px;
            font-weight: var(--font-bold);
            margin-bottom: 15px;

            @media (min-width: $desktop_md) {
                font-size: 19px;
            }
        }

        .colores-firstpart-container {


            @media (min-width: $tablet) {
                margin-top: 20px;
            }

            @media (min-width: $desktop_md) {
                margin-top: 30px;
            }

            .color-options-container {
                display: flex;
                flex-wrap: wrap;
                

                @media (min-width: $tablet) {
                    //flex-wrap: unset;
                    // justify-content: space-between;
                    margin-bottom: 20px;
                    row-gap: 10px;
                }

                .color-option-item {
                    border: 1px solid var(--color-grey-primary);
                    padding: 12px 18px;
                    color: var(--color-grey-primary);
                    transition: background var(--transition),
                        font-weight var(--transition),
                        color var(--transition),
                        opacity var(--transition);
                    position: relative;
                    cursor: pointer;
                    font-size: 12px;
                    margin-bottom: 14px;
                    margin-right: 16px;


                    @media (min-width: $tablet) {
                        font-size: 14px;
                        margin-right: 0px;
                        margin-bottom: 0px;
                        padding: 8px 20px;
                        margin-right: 10px;
                    }

                    @media (min-width: $desktop_md) {
                        font-size: 16px;
                        min-width: 110px;
                        margin-right: 15px;
                    }

                    &:hover {
                        opacity: .8;
                    }

                    &.active {
                        background: var(--color-grey-primary);
                        font-weight: var(--font-bold);
                        color: var(--color-white-primary);

                        @media (max-width: $tablet) {
                            padding: 13px 27px;
                        }

                        /*
                        &::after {
                            content: '';
                            position: absolute;
                            top: 35px;
                            left: 10px;
                            width: 0;
                            height: 0;
                            border-left: 12px solid transparent;
                            border-right: 12px solid transparent;
                            border-bottom: 10px solid var(--color-grey-primary);
                            transform: rotate(180deg);

                            @media (max-width: $tablet) {
                                top: 40px;
                            }

                            @media (min-width: $tablet) and (max-width: $desktop_md) {
                                top: 32px;
                            }
                        }
                        */
                    }
                }
            }

            .color-display-container {
                background: rgba($color: #A1A1A1, $alpha: .29);
                padding: 10px;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                width: 100%;

                @media (max-width:$tablet) {
                    // justify-content: space-between;
                }

                @media (max-width: $tablet) {
                    background: none;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100vw;
                        height: 100%;
                        z-index: -1;
                        background: rgba($color: #A1A1A1, $alpha: .29);
                        left: -5vw;
                        top: 0;
                    }
                }

                .color-display-item {
                    margin-right: 25px;
                    margin-bottom: 6px;
                    margin-top: 6px;
                    width: 57px;
                    height: 57px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    transition: box-shadow var(--transition);

                    @media (min-width: $tablet) {
                        margin-right: 0%;
                        margin-left: 25px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        width: 45px;
                        height: 45px;
                    }

                    @media (min-width: $desktop_md) {
                        width: 60px;
                        height: 60px;
                    }

                    &:hover,
                    &.active {
                        box-shadow: 0 0 0 4pt var(--color-grey-mediumdark);
                    }

                    img {
                        max-width: calc(100% + 24px);
                        max-height: calc(100% + 24px);

                        @media (min-width: $tablet) {
                            max-width: calc(100% + 16px);
                            max-height: calc(100% + 16px);
                        }

                    }
                }
            }
        }

        .colores-secondpart-container {
            overflow: auto;
            margin-top: 20px;

            @media (min-width: $desktop_md) {
                margin-top: 30px;
            }

            .color-results-container {

                @media (min-width: $tablet) {
                    display: flex;
                }

                .color-results-main {
                    min-width: 40%;

                    @media (min-width: $desktop_md) {
                        min-width: 37%;
                    }

                    .main-item {
                        width: 144px;
                        height: 144px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        @media (max-width: $tablet) {
                            margin-bottom: 20px;
                        }

                        @media (min-width: $tablet) {
                            width: 100px;
                            height: 100px;
                        }

                        @media (min-width: $desktop_md) {
                            width: 120px;
                            height: 120px;
                        }

                        img {
                            max-width: calc(100% + 55px);
                            max-height: calc(100% + 55px);

                            @media (min-width: $tablet) {
                                max-width: calc(100% + 45px);
                                max-height: calc(100% + 45px);
                            }

                        }

                        p {
                            position: absolute;
                            font-weight: var(--font-bold);
                            color: var(--color-white-primary);
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 15px;
                            line-height: 1.2;
                            min-width: 70px;
                            text-align: center;

                            @media (min-width: $tablet) {
                                font-size: 12px;
                                line-height: 1.5;
                            }

                            @media (min-width: $desktop_md) {
                                font-size: 15px;
                            }
                        }
                    }
                }

                .color-results-other {



                    .other-item {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: grey;
                        margin-right: 10px;
                        overflow: hidden;

                        @media (min-width: $tablet) {
                            width: 40px;
                            height: 40px;
                        }

                        @media (min-width: $desktop_md) {
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;
                        }

                        img {
                            width: calc(100% + 13px);
                            height: calc(100% + 13px);
                            transform: translate(-6px, -6px);
                        }
                    }

                    .other-arrows {
                        display: flex;
                        align-items: center;

                        @media (max-width: $tablet) {
                            margin-bottom: 22px;
                        }

                        .other-arrows-item {
                            width: 70px;
                            height: 22px;
                            position: relative;
                            margin-right: 1px;
                            transform: translateX(-5px);

                            @media (min-width: $tablet) {
                                width: 50px;
                                height: 25px;
                            }


                            @media (min-width: $desktop_md) {
                                transform: translateX(0px);
                                width: 50px;
                                margin-right: 15px;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                left: calc(50% - 1.5px);
                                border: solid #BEBAB7;
                                border-width: 0 3px 3px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: rotate(45deg) translateX(-50%);
                                margin-top: 10px;

                                @media (min-width: $tablet) {
                                    border-width: 0 4px 4px 0;
                                    padding: 5px;
                                    left: 18px;
                                    transform: rotate(45deg);
                                    margin-top: 0px;
                                }
                            }
                        }
                    }

                    p {
                        color: var(--color-dark-primary);
                        font-size: 12px;

                        @media (min-width: $desktop_md) {
                            font-size: 14px;
                        }
                    }

                    .other-firstpart,
                    .other-secondpart {
                        @media (min-width: $tablet) {
                            display: flex;
                            align-items: center;
                        }

                        .imgs-container {
                            display: flex;
                            align-items: center;
                        }

                        .mobile-title {
                            margin-bottom: 10px;

                            @media (min-width: $tablet) {
                                display: none;
                            }
                        }

                        .desktp-title {
                            @media (max-width: $tablet) {
                                display: none;
                            }
                        }

                    }

                    .other-firstpart {
                        margin-bottom: 0px;
                    }

                    .other-secondpart {
                        @media (max-width: $tablet) {
                            margin-bottom: 38px;
                        }
                    }
                }
            }
        }

    }
}